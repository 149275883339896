import * as React from "react";
import "./busca-paginator.css";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";
import BasicSelect from "../select/base-select";
import { connect } from "react-redux";

const PAGE_DEFAULT_TYPE = "PAGE";
const PAGE_CURRENT_TYPE = "CURRENT";
const pageTypesMapper = {
  NEXT: () => <FaAngleRight />,
  PREVIOUS: () => <FaAngleLeft />,
  FIRST: () => <FaAngleDoubleLeft />,
  LAST: () => <FaAngleDoubleRight />,
};
 class BuscaPaginator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pagination: props?.pagination,
    };

    this.goToPage = this.goToPage.bind(this);
    this.handleView = this.handleView.bind(this);
    this.handleSpecificButtons = this.handleSpecificButtons.bind(this);
  }

  handleSpecificButtons(page) {
    if (page.type === PAGE_CURRENT_TYPE || page.type === PAGE_DEFAULT_TYPE) {
      return page.text;
    }
    return <>{pageTypesMapper[page.type]?.()}</>;
  }

  goToPage(page) {
    if (this.props.onChange) {
      this.props.onChange(page.page);
    }
  }

  handleView() {
    return this.props?.pagination?.map((page) => (
      <button
        className={`busca-paginator-btn ${page?.type?.toLowerCase()}`}
        key={page.page}
        onClick={() => this.goToPage(page)}
      >
        <span>{this.handleSpecificButtons(page)}</span>
      </button>
    ));
  }

  render() {
    return (
      <div className={`busca-pagination ${this.props.isContraste ? 'contraste-pag' : ''}`}>
        <p className="busca-paginator-detail">
          Mostrando {this.props?.queryContext?.pageStart} de{" "}
          {this.props?.queryContext?.pageEnd} - total{" "}
          {this.props?.queryContext?.count}
        </p>
        <div className="busca-pagination-btn-flex ">{this.handleView()}</div>
        <div className="busca-66">
          <p className="busca-paginator-detail">Por página</p>
          <BasicSelect
            ignoreEmpty
            selected={this.props?.amountPage}
            onChange={async (amount) => {
              if (this.props.amountChange) {
                this.props.amountChange(amount);
              }
            }}
            options={[
              { id: '10', label: "10" },
              { id: '25', label: "25" },
              { id: '50', label: "50" },
            ]}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(BuscaPaginator);
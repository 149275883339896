import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Iframe from "react-iframe";
import Arquivos from "../arquivos/index";
import { connect } from 'react-redux';

class Licitacao extends React.Component {

    constructor(props) {
        super(props);
        this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
        this.state = {
            botaoSelecionado: "Licitacoes",
            iframeSelecionado: "Licitacoes"
        }
    }

    ativarBotaoIframe = (nome) => {
        this.setState({
            botaoSelecionado: nome,
            iframeSelecionado: nome
        })
    }


    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <>

                <Arquivos>
                 {<Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/licitacao.html?uf=" + this.props.estado.toUpperCase()}
                     width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    padding="0"
                    margin="0" />} 
                </Arquivos>

                </>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
});

export default connect(mapStateToProps)(Licitacao);

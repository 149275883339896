import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";

class Contabel extends React.Component {
    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <Arquivos hasDateFilter>
                </Arquivos>
            </div>
        );
    }
}

export default Contabel;
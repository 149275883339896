const urlSearchParams = new window.URLSearchParams(document.location.search);

const INITIAL_STATE = {
    isLoading: false,
    fontSize:24,
    isContraste:false,
    estado: urlSearchParams.get('unidade') ?? '',
    uf: urlSearchParams.get('uf') ?? '',
    botaoSelecionado:"",
    sideBarSelecionado: "Institucional"
  };
  
  export default function getState(state = INITIAL_STATE, action) {
    switch (action.type) {
      case 'LOADING':
        return { ...state, 'isLoading': action.isLoading }
      case 'FONTSIZE':
        return { ...state, 'fontSize': action.fontSize + state.fontSize }
      case 'CONTRASTE':
        return { ...state, 'isContraste': !state.isContraste }
      case "ESTADO":
        return {...state,'estado':action.estado}  
      case "UF":
        return {...state,'uf':action.uf}  
      case "BOTAO":
        return {...state,'botaoSelecionado':action.botaoSelecionado}  
      case "SIDEBAR":
        return {...state,'sideBarSelecionado': action.sideBarSelecionado}
      default:
        return state;
    }
  }
  
/* eslint-disable */
import React from "react";
import { FaSortDown } from "react-icons/fa";
import Engrenagem from "../../images/engrenagem.png";
import Brasil from "../../images/brasil.png";
import BrasilMapa from "../../images/brasil-mapa.png";
import EngrenagemB from "../../images/engrenagem_branco.png";
import BrasilB from "../../images/brasil_branco.png";
import BrasilMapaB from "../../images/brasil-mapa_branco.png";
import "./botoes.css";
import "./contraste.css";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setEstado, setBotao, setSideBar } from "../../redux/actions";
import { withRouter } from "react-router-dom";
import CampoBusca from "../busca/busca";

class Botoes extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      UF: "Selecione",
      contraste: true,
      ufs: {
        " ": "Selecione",
        na: "Nacional",
        ac: "Acre",
        al: "Alagoas",
        ap: "Amapá",
        am: "Amazonas",
        ba: "Bahia",
        ce: "Ceará",
        df: "Distrito Federal",
        es: "Espirito Santo",
        go: "Goias",
        ma: "Maranhão",
        mt: "Mato Grosso",
        ms: "Mato Grosso do Sul",
        mg: "Minas Gerais",
        pa: "Pará",
        pb: "Paraíba",
        pr: "Paraná",
        pe: "Pernambuco",
        pi: "Piauí",
        rj: "Rio de Janeiro",
        rn: "Rio Grande do Norte",
        rs: "Rio Grande do Sul",
        ro: "Rondônia",
        rr: "Roraima",
        sc: "Santa Catarina",
        sp: "São Paulo",
        se: "Sergipe",
        to: "Tocantins",
      },
      isMain: this.props.location.pathname.trim().split("/")[1] === "mapa",
    };
    this.mudarUF = this.mudarUF.bind(this);
    this.ativarBotao = this.ativarBotao.bind(this);
    this.activateButon = this.activateButon.bind(this);
  }

  mudarUF = async (uf) => {
    await this.props.setEstado(uf);
    this.setState({
      UF: this.state.ufs[this.props.estado],
    });
    //await this.props.setSideBar("esic");
    if (this.state.isMain) this.props.history.push("/institucional");
  };

  ativarBotao = (nome, elemento, isDropdown = false) => {
    let ativos = document.getElementsByClassName(
      "grid-cabecalho-header-box-active"
    );
    let dropdown_ativo = document.getElementsByClassName(
      "grid-cabecalho-header-dropdown-left-active"
    );
    for (let at of ativos)
      at.setAttribute("class", "grid-cabecalho-header-box");
    for (let dr of dropdown_ativo)
      dr.setAttribute("class", "grid-cabecalho-header-dropdown-left");

    if (!isDropdown)
      elemento.setAttribute("class", "grid-cabecalho-header-box-active");
    else
      elemento.setAttribute(
        "class",
        "grid-cabecalho-header-dropdown-left-active"
      );
    this.props.setBotao(nome);
  };

  activateButon = () => {
    if (this.props.botaoSelecionado !== "") {
      if (this.props.botaoSelecionado !== "SebraeEstados")
        this.refs[this.props.botaoSelecionado].setAttribute(
          "class",
          "grid-cabecalho-header-box-active"
        );
      else
        this.refs[this.props.botaoSelecionado].setAttribute(
          "class",
          "grid-cabecalho-header-dropdown-left-active"
        );
    }
  };

  componentDidMount() {
    if (this.props.estado !== "") {
      this.setState({
        UF: this.state.ufs[this.props.estado],
      });
    } else {
      this.setState({
        UF: "Selecione",
      });
    }
    this.activateButon();
  }

  render() {
    const estados = [
      [" ", "Selecione"],
      ["na", "Nacional"],
      ["ac", "Acre"],
      ["al", "Alagoas"],
      ["ap", "Amapá"],
      ["am", "Amazonas"],
      ["ba", "Bahia"],
      ["ce", "Ceará"],
      ["df", "Distrito Federal"],
      ["es", "Espirito Santo"],
      ["go", "Goias"],
      ["ma", "Maranhão"],
      ["mt", "Mato Grosso"],
      ["ms", "Mato Grosso do Sul"],
      ["mg", "Minas Gerais"],
      ["pa", "Pará"],
      ["pb", "Paraíba"],
      ["pr", "Paraná"],
      ["pe", "Pernambuco"],
      ["pi", "Piauí"],
      ["rj", "Rio de Janeiro"],
      ["rn", "Rio Grande do Norte"],
      ["rs", "Rio Grande do Sul"],
      ["ro", "Rondônia"],
      ["rr", "Roraima"],
      ["sc", "Santa Catarina"],
      ["sp", "São Paulo"],
      ["se", "Sergipe"],
      ["to", "Tocantins"],
    ];

    const dropdown_estados = estados.map(([sigla, estado], key) => {
      return (
        <a key={key} href="#" alt={estado} onClick={() => this.mudarUF(sigla)}>
          {estado}
        </a>
      );
    });

    return (
      <div
        className={
          this.props.isContraste ? "contraste-grid-cabecalho" : "grid-cabecalho"
        }
      >
        <div className="grid-cabecalho-header">
          <div
            ref="SistemaSebrae"
            onClick={async () => {
              this.ativarBotao("SistemaSebrae", this.refs.SistemaSebrae);
              if (this.state.isMain) {
                this.props.history.push("/institucional");
              }
              await this.mudarUF(" ");
            }}
            className="grid-cabecalho-header-box"
          >
            <img
              src={this.props.isContraste ? EngrenagemB : Engrenagem}
              alt="Engrenagem do Sistema Sebrae"
            />
            <a href="#" alt="sistema sebrae">
              Sistema Sebrae
            </a>
          </div>
          <div
            ref="SistemaNacional"
            onClick={async () => {
              this.ativarBotao("SistemaNacional", this.refs.SistemaNacional);
              //await this.props.setSideBar("esic") ;
              await this.mudarUF("na");
              if (this.state.isMain) {
                this.props.history.push("/institucional");
              }
            }}
            className="grid-cabecalho-header-box"
          >
            <img
              src={this.props.isContraste ? BrasilB : Brasil}
              alt="Bandeira do Brasil"
            />
            <a href="#" alt="Sebrae Nacional">
              Sebrae Nacional
            </a>
          </div>
          <div className="grid-cabecalho-header-dropdown">
            <div
              ref="SebraeEstados"
              className="grid-cabecalho-header-dropdown-left"
              onClick={() =>
                this.ativarBotao("SebraeEstados", this.refs.SebraeEstados, true)
              }
            >
              <div style={{ display: "flex" }}>
                <img
                  src={this.props.isContraste ? BrasilMapaB : BrasilMapa}
                  alt="Mapa do Brasil"
                />
                <a href="#" alt="Sebrae nos estados">
                  Sebrae nos <br /> Estados
                </a>
              </div>
              <div className="grid-cabecalho-header-dropdown-search">
                {this.props.uf_atual ? (
                  <a href="#" alt="Estado selecionado">
                    {" "}
                    {this.props.uf_atual}{" "}
                  </a>
                ) : (
                  <a href="#" alt="Estado selecionado">
                    {" "}
                    {this.state.UF}{" "}
                  </a>
                )}
                <button
                  className="grid-cabecalho-header-dropdown-btn"
                  aria-label="selecionar estado"
                >
                  <FaSortDown className="btn-icon" />
                  <div className="grid-cabecalho-header-dropdown-content">
                    {dropdown_estados}
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div style={{ width: "25%", height: 66 }}>
            <CampoBusca />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
  estado: store.principal.estado,
  botaoSelecionado: store.principal.botaoSelecionado,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setEstado, setBotao, setSideBar }, dispatch);

export default connect(mapStateToProps,  mapDispatchToProps)(withRouter(Botoes));


import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Formulario from "../formulario/index";
import { connect } from "react-redux";
import Busca from "../buscamanifest/index";
import "./sic.css";
import Arquivos from "../arquivos/index";

class SicConteudo extends React.Component {
  render() {
    return (
      <div
        className={
          this.props.isContraste ? "contraste-display-grid" : "display-grid"
        }
      >
        <div className="sic-cabecalho">
          <Breadcrumb />
        </div>
        <div className="display-texto">
          <div className="sic-cabecalho">
            <div>
              <h2>SIC</h2>
              <p>
                O Serviço de Informação ao Cidadão do SEBRAE – SIC permite que
                qualquer pessoa, física ou jurídica, apresente pedidos de acesso
                a informações custodiadas pelo Sistema SEBRAE.
              </p>
              <p>
                Maiores informações veja nossas “Perguntas mais frequentes”
                disponíveis em{" "}
                <a
                  href="#sic-faq"
                  style={
                    this.props.isContraste
                      ? {
                          textDecoration: "none",
                          fontWeight: "bold",
                          color: "yellow",
                        }
                      : {
                          textDecoration: "none",
                          fontWeight: "bold",
                          color: "#333",
                        }
                  }
                >
                  FAQ
                </a>
              </p>
            </div>
            <Busca />
          </div>
          {/* <div className="recesso">
            <p >Prezados(as) Clientes,</p>
            <p >
              O SIC SEBRAE informa que os pedidos registrados no período de 24
              de dezembro de 2021 a 07 de janeiro de 2022 terão seus prazos
              suspensos, sendo tratados a partir do dia 10 de janeiro.
            </p>
            <p >
              Agradecemos a compreensão e desejamos um próspero Ano Novo.
            </p>
          </div> */}

          {/* <a className="btn-sic" href="#formulario">Nova Solicitação</a>
                    <h3 style={{ marginTop: "40px" }} >Como funciona</h3>

                    <div className="sic-imagens-container" >
                        <div className="sic-imagens-container-web">
                            <img src={Imagem3} className="sic-imagens" />
                        </div>
                        <div className="sic-imagens-container-mobile">
                            <img src={Imagem4} className="sic-imagens" />   
                        </div>
                    </div>
                    <div id="formulario"></div> */}
          <a className="btn-sic" href="#formulario">
            Nova Solicitação
          </a>
          <Arquivos>
            <Formulario id="formulario_manifestacao" />
            <div id="formulario"></div>

            <div className="display-texto">
              <h3>Outras formas de falar com o SIC:</h3>
              <ul>
                <li style={{ paddingBottom: "5px", fontSize: "16px" }}>
                  E-mail: sic@sebrae.com.br
                </li>
                <li style={{ paddingBottom: "5px", fontSize: "16px" }}>
                  {" "}
                  Telefone: (61) 3348-7100
                </li>
                <li style={{ paddingBottom: "5px", fontSize: "16px" }}>
                  Carta: SEBRAE Nacional – SGAS Quadra 605 Conjunto A –
                  Brasília/DF – CEP 70200-904.
                </li>
              </ul>
            </div>
          </Arquivos>
          <div id="sic-faq"></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(SicConteudo);

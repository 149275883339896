const axios = require("axios");
const glpiUrl = process.env.REACT_APP_GLPI_URL;
const glpiApiURL = glpiUrl + "apirest.php/";
const tokenUserGLPI = process.env.REACT_APP_TOKEN_USER_GLPI;
const tokenAppGLPI = process.env.REACT_APP_TOKEN_APP_GLPI;

const initSession = async () => {
  let headers = {
    headers: {
      Authorization: "user_token " + tokenUserGLPI,
      "App-Token": tokenAppGLPI,
    },
  };
  return await axios.get(glpiApiURL + "initSession", headers);
};

const killSession = async (session) => {
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return await axios.get(glpiApiURL + "killSession", headers);
};

const getRequester = async (session, idTicket) => {
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return await axios.get(
    glpiApiURL + "Ticket/" + idTicket + "/Ticket_User",
    headers
  );
};

const getUser = async (session, email) => {
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return await axios.get(
    glpiApiURL + "User?searchText[name]=" + email,
    headers
  );
};

export const getITILSolution = async (idTicket) => {
  let resposta = await initSession();
  let session = resposta.data.session_token;
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return axios.get(
    glpiApiURL + "Ticket/" + idTicket + "/ITILSolution",
    headers
  );
};

export const getITILFollowup = async (idTicket) => {
  let resposta = await initSession();
  let session = resposta.data.session_token;
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return axios.get(
    glpiApiURL + "Ticket/" + idTicket + "/ITILFollowup",
    headers
  );
};

const removeRequerente = async (session, idTicket) => {
  let requerente = await getRequester(session, idTicket);
  if (!requerente?.length) {
    return;
  }
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return await axios.delete(
    glpiApiURL +
      "Ticket/" +
      idTicket +
      "/Ticket_User/" +
      requerente.data?.[0]?.id,
    headers
  );
};

const addRequester = async (session, idTicket, email) => {
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  let user = [];
  try {
    user = await getUser(session, email);
  } catch (err) {
    console.log(err);
  }
  let newRequester = {
    input: {
      tickets_id: idTicket,
      users_id: 0,
      type: 1,
      use_notification: 1,
      alternative_email: email,
    },
  };
  if (user.data.length > 0) {
    newRequester = {
      input: {
        tickets_id: idTicket,
        users_id: user.data[0].id,
        type: 1,
        use_notification: 1,
      },
    };
  }
  return await axios.post(glpiApiURL + "Ticket_User", newRequester, headers);
};

const postCriarTicket = async (session, ticket) => {
  let headers = {
    headers: {
      "Session-Token": session,
      "App-Token": tokenAppGLPI,
    },
  };
  return await axios.post(glpiApiURL + "Ticket", ticket, headers);
};

export const criarTicket = async (
  descricao,
  titulo,
  email,
  idEntidade = 42
) => {
  let resposta = await initSession();
  let session = resposta.data.session_token;
  let newTicket = {
    input: {
      entities_id: idEntidade,
      name: titulo,
      content: descricao,
      requesttypes_id: 8,
    },
  };
  let ticket = await postCriarTicket(session, newTicket);
  await removeRequerente(session, ticket.data.id);
  await addRequester(session, ticket.data.id, email);
  await killSession(session);
  return ticket.data.id;
};

import * as React from "react";
import "./busca-page.css";
import BuscaBreadCrumb from "./busca-breadcrumb";
import BuscaField from "../../components/busca/busca";
import BasicSelect from "../../components/select/base-select";
import BuscaApiService from "../../services/buscaService";
import BuscaCard from "../../components/busca/busca-card";
import BuscaPaginator from "../../components/busca/busca-paginator";
import BuscaTags from "../../components/busca/busca-tags";
import { RingLoader } from "react-spinners";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  CategoriaOptions,
  TipoOptions,
  UfsOptions,
} from "../../components/busca/busca-options-select";
import { FaFilter } from "react-icons/fa";

const handleQuery = (search) =>
  new URLSearchParams(window.location.search).get(search) ?? "";

class BuscaPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: handleQuery("q"),
      uf: { id: handleQuery("uf"), label: handleQuery("ufl") },
      category: { label: handleQuery("cl"), id: handleQuery("c") },
      type: { id: handleQuery("t"), label: handleQuery("tl") },
      sort: {
        id: handleQuery("s") || "relevance",
        label: handleQuery("sl") || "Relevantes",
      },
      currentPage: handleQuery("p") || 1,
      rows: { id: handleQuery("r") || 10, label: handleQuery("r") || 10 },
      documentos: [],
      isLoading: true,
      pagination: [],
    };

    this.getFilter = this.getFilter.bind(this);
    this.mountQueryUrl = this.mountQueryUrl.bind(this);
    this.handleQueryCallback = this.handleQueryCallback.bind(this);
    this.displayInfo = this.displayInfo.bind(this);
    this.clearFiltros = this.clearFiltros.bind(this);
    this.groupByType = this.groupByType.bind(this);
    this.updateUrl = this.updateUrl.bind(this);
  }

  mountQueryUrl() {
    const form = {
      query: this.state.search || "*",
      uf: this.state.uf?.id,
      category: this.state.category?.id,
      type: this.state.type?.id,
      page: this.state.currentPage,
      sort: this.state.sort?.id,
      rows: this.state.rows?.id,
    };

    return form;
  }

  async handleQueryCallback(data) {
    this.setState({
      documentos: data?.data?.results?.document,
      pagination: data?.data?.pagination,
      queryContext: data?.data?.queryContext,
    });
  }

  checkExist(bool, str) {
    if (bool) {
      return `${str}=${bool}&`;
    }
    return "";
  }

  updateUrl() {
    const queryParams = `${this.checkExist(
      this.state.search,
      "q"
    )}${this.checkExist(this.state.category?.id, "c")}${this.checkExist(
      this.state.currentPage,
      "p"
    )}${this.checkExist(this.state.uf?.id, "uf")}${this.checkExist(
      this.state.type?.id,
      "t"
    )}${this.checkExist(
      this.state.rows?.id,
      "r"
    )}${this.checkExist(this.state.category?.label, "cl")}${this.checkExist(
      this.state.uf?.label,
      "ufl"
    )}${this.checkExist(this.state.type?.label, "tl")}${
      this.checkExist(this.state.sort?.label, "sl")
    }${this.checkExist(this.state.sort?.id, "s")}`;
    this.props.history.push(`/busca?${queryParams}`);
  }

  async getFilter() {
    window.scrollTo(0, 0);
    this.setState({ documentos: [], isLoading: true, pagination: [] });
    this.updateUrl();

    return BuscaApiService.getByQuery({ ...this.mountQueryUrl() })
      .then(this.handleQueryCallback)
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  groupByType() {
    const paginas = [];
    const arquivos = [];
    this.state.documentos.forEach((doc) => {
      if (doc?.fields?.type === "LAI-CTD-PAGINA") {
        paginas.push(doc);
      }
      if (doc?.fields?.type === "LAI-CTD-ARQUIVO") {
        arquivos.push(doc);
      }
    });
    return (
      <>
        {paginas?.length > 0 && (
          <div className="busca-container-info">
            <p className="busca-info-title">Textos</p>
            <ul>
              {paginas.map((ele, id) => (
                <BuscaCard key={id} content={ele} />
              ))}
            </ul>
          </div>
        )}
        {arquivos?.length > 0 && (
          <div className="busca-container-info">
            <p className="busca-info-title">Documentos</p>
            <ul>
              {arquivos.map((ele, id) => (
                <BuscaCard key={id} content={ele} />
              ))}
            </ul>
          </div>
        )}
      </>
    );
  }

  displayInfo() {
    if (this.state.isLoading) {
      return (
        <div className="busca-loading">
          <RingLoader
            sizeUnit={"px"}
            size={150}
            color={this.props.isContraste ? "#ff0" : "rgb(37, 127, 245)"}
            loading={this.state.isLoading}
          />
        </div>
      );
    }

    return (
      <>
        <div className="busca-sorting">
          <p className="busca-sorting-detail">
            Mostrando {this.state?.queryContext?.pageStart} de{" "}
            {this.state?.queryContext?.pageEnd} - total{" "}
            {this.state?.queryContext?.count}
          </p>
          <div className="busca-sort-flex">
            <p className="busca-sorting-detail">Ordenar por: </p>
            <BasicSelect
              ignoreEmpty
              onChange={async (sort) => {
                await this.setState({ sort, currentPage: 1 });
                await this.getFilter();
              }}
              selected={this.state.sort}
              options={[
                { id: "relevance", label: "Relevantes" },
                { id: "newest", label: "Mais recentes" },
                { id: "oldest", label: "Mais antigos" },
              ]}
            />
          </div>
        </div>
        {this.groupByType()}
        <BuscaPaginator
          amountPage={this.state.rows}
          amountChange={async (r) => {
            await this.setState({ rows: r });
            await this.getFilter();
          }}
          onChange={async (page) => {
            await this.setState({ currentPage: page });
            await this.getFilter();
          }}
          pagination={this.state.pagination}
          queryContext={this.state.queryContext}
          amount={this.state.documentos.length}
        />
      </>
    );
  }

  async clearFiltros() {
    await this.setState({
      uf: { label: "" },
      category: { label: "" },
      year: { label: "" },
      type: { label: "" },
    });
    this.getFilter();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getFilter();
  }

  render() {
    return (
      <div
        className={`main-busca-container ${
          this.props.isContraste ? "contraste" : ""
        }`}
      >
        <div className="desktop-delimiter ">
          <BuscaBreadCrumb />
          <BuscaField
            onSubmit={async (value) => {
              await this.setState({ search: value, currentPage: 1 });
              await this.getFilter();
            }}
            inBusca={true}
            initBusca={this.state.search}
          />
          <div className="selects-grid">
            <div className="busca-filter-flex">
              <FaFilter color="#005EB8" />
              <p>Filtre os resultados: </p>
            </div>
            <BasicSelect
              isDisabled={this.state.isLoading}
              selected={this.state.uf}
              onChange={async (uf) => {
                await this.setState({ uf, currentPage: 1 });
                await this.getFilter();
              }}
              label="Unidade"
              options={UfsOptions}
            />
            <BasicSelect
              isDisabled={this.state.isLoading}
              selected={this.state.category}
              onChange={async (category) => {
                await this.setState({ category, currentPage: 1 });
                await this.getFilter();
              }}
              label="Categoria"
              options={CategoriaOptions}
            />
            <BasicSelect
              isDisabled={this.state.isLoading}
              selected={this.state.type}
              onChange={async (type) => {
                await this.setState({ type, currentPage: 1 });
                await this.getFilter();
              }}
              label="Tipo"
              options={TipoOptions}
            />
          </div>
          <BuscaTags
            clearFilter={() => this.clearFiltros()}
            handleClose={async (key) => {
              await this.setState({ [key]: { label: "" } });
              this.getFilter();
            }}
            tags={{
              uf: this.state.uf?.label,
              year: this.state.year?.label,
              type: this.state.type?.label,
              category: this.state.category?.label,
            }}
          />
          {this.displayInfo()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(withRouter(BuscaPage));

import * as React from "react";
import "./base-select.css";
import { connect } from "react-redux";
import OutsideAlerter from "./outside-event-select";
import { FaSortDown, FaSortUp } from "react-icons/fa";

class BasicSelect extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.toggle = this.toggle.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.state = {
      open: false,
      label: props?.label ?? "",
      options: props?.options ?? [],
    };
  }

  toggle(bool) {
    this.setState({ open: bool });
  }

  onSelect(opt) {
    if (this.props.onChange) {
      this.props.onChange(opt);
    }
  }

  render() {
    return (
      <OutsideAlerter handleOutsideClick={() => this.toggle(false)}>
        <div
          className={`select-wrapper ${
            this.props?.isContraste ? "contraste-bs" : ""
          }`}
        >
          <p className="select-label">{this.state.label}</p>
          <input
            disabled={this.props?.isDisabled}
            onFocus={() => this.toggle(true)}
            readOnly
            className={`main-select${this.state.open ? "-focus" : ""}`}
            value={this.props?.selected?.label || "Selecione"}
          />
          <div className="angle">
            {this.state.open ? <FaSortDown /> : <FaSortUp />}
          </div>
          {this.state.open && (
            <div className="white-relative ">
              <div
                className={`hide-glow${
                  this.props?.ignoreEmpty ? "-ignore" : ""
                }`}
              ></div>
              <ul role="menu">
                {!this.props?.ignoreEmpty && (
                  <li
                    onClick={() => {
                      this.onSelect({
                        id: 0,
                        label: "Selecione",
                      });
                      this.toggle(false);
                    }}
                    role="menuitem"
                    tabIndex={-1}
                  >
                    <span>Selecione</span>
                  </li>
                )}
                {this.state.options.map((opt) => (
                  <li
                    className={
                      opt.id === this.props?.selected.id ? "selected" : ""
                    }
                    role="menuitem"
                    key={opt.id}
                    tabIndex={-1}
                    value={opt.label}
                    onClick={() => {
                      this.onSelect(opt);
                      this.toggle(false);
                    }}
                  >
                    <span>{opt.label}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </OutsideAlerter>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(BasicSelect);

import React from 'react';
import ReactDOM from 'react-dom';
import "react-app-polyfill/jsdom";
import 'react-app-polyfill/stable';
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import indexRoutes from './routes/index';
import "./variaveis.css";
import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Store } from './redux/store';
import 'react-notifications/lib/notifications.css';
import { SebraeCookieConsent } from '@sebrae/cookie-consent';

const hist = createBrowserHistory();

ReactDOM.render(
  <div className={Store.isContraste ? "contraste-backgroundDefault" : "backgroundDefault"}>
    <SebraeCookieConsent uuidApp="018f3946-a5b6-7ec8-878a-158b9ddb173e" />
    <Provider store={Store}>
      <div className={Store.isContraste ? "contraste-backgroundDefault" : "backgroundDefault"}>
        <Router history={hist}>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return <Route path={prop.path} key={key} component={prop.component} />;
            })}

          </Switch>
        </Router>
      </div>
    </Provider>
  </div>
  ,
  document.getElementById("root")
);
serviceWorker.register();

import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Iframe from "react-iframe";
import Arquivos from "../arquivos/index";
import { connect } from 'react-redux';

class Credenciamento extends React.Component {

    constructor(props) {
        super(props);
        this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
        this.state = {
            botaoSelecionado: "Credenciados",
            iframeSelecionado: "Credenciados"
        }
    }

    ativarBotaoIframe = (nome) => {
        this.setState({
            botaoSelecionado: nome,
            iframeSelecionado: nome
        })
    }

    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <>

                <Arquivos>
                <div className="submenu-opcoes">
                        <button
                            className={this.state.botaoSelecionado === "Credenciados" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Credenciados")} >Lista de Credenciados</button>
                        <button
                            className={this.state.botaoSelecionado === "Descredenciados" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Descredenciados")} >Lista de Descredenciados</button>
                </div>
                {this.state.iframeSelecionado === "Credenciados" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/credenciados.html?uf=" + this.props.estado.toUpperCase()}
                     width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    padding="0"
                    margin="0" />}
                 {this.state.iframeSelecionado === "Descredenciados" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/descredenciados.html?uf=" + this.props.estado.toUpperCase()}
                     width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    padding="0"
                    margin="0" />}
                </Arquivos>
                </>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
});

export default connect(mapStateToProps)(Credenciamento);
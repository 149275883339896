import * as React from "react";
import "./campo-busca.css";
import LupaSvg from "../../images/lupa.svg";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

class Busca extends React.Component {
  constructor(props) {
    super(props);

    this.image = LupaSvg;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateFormField = this.updateFormField.bind(this);

    this.state = {
      search: props.initBusca ?? "",
      inBusca: props.inBusca,
    };
  }

  updateFormField(e) {
    this.setState({ search: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (!this.state.inBusca) {
      this.props.history.push(`/busca?q=${this.state.search}`);
    }
    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.search);
    }
  }

  render() {
    return (
      <form
        onSubmit={this.handleSubmit}
        className={`${
          this.props.isContraste ? "contraste-field-form" : ""
        } input-field-card${this.state.inBusca ? "-busca" : ""}`}
      >
        <input
          onChange={this.updateFormField}
          value={this.state.search}
          placeholder="Pesquisar"
          className="input-field"
        />
        <button type="submit">
          <img src={this.image} alt="lupa" />
        </button>
      </form>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default withRouter(connect(mapStateToProps)(Busca));

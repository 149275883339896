export const CategoriaOptions = [
  { id: "Institucional", label: "Institucional" },
  { id: "Normativos", label: "Atos normativos" },
  { id: "Projeto", label: "Estratégias e resultados" },
  {
    id: "Orçamentos",
    label: "Orçamento e execução orçamentária",
  },
  { id: "Licitação", label: "Licitações" },
  { id: "Gestão", label: "Gestão Financeira" },
  { id: "Credenciamento", label: "Credenciamento" },
  { id: "Contratos", label: "Contratos" },
  { id: "Convêncios", label: "*Convêncios e Transferências" },
  { id: "Contábel", label: "Demonstrações contábeis" },
  { id: "Dirigentes", label: "Dirigentes e empregados" },
  { id: "Contas", label: "Prestação de contas" },
  {
    id: "Infraestrutura",
    label: "Infraestrutura de atendimento",
  },
  { id: "Lei", label: "Lei de acesso a informação" },
  { id: "LGPD", label: "Lei Geral de Proteçao de Dados" },
  { id: "SIC", label: "SIC" },
  { id: "Integridade", label: "Integridade" },
  { id: "Inovacao", label: "Inovação" },
];

export const UfsOptions = Object.entries({
  na: "Nacional",
  ac: "Acre",
  al: "Alagoas",
  ap: "Amapá",
  am: "Amazonas",
  ba: "Bahia",
  ce: "Ceará",
  df: "Distrito Federal",
  es: "Espirito Santo",
  go: "Goias",
  ma: "Maranhão",
  mt: "Mato Grosso",
  ms: "Mato Grosso do Sul",
  mg: "Minas Gerais",
  pa: "Pará",
  pb: "Paraíba",
  pr: "Paraná",
  pe: "Pernambuco",
  pi: "Piauí",
  rj: "Rio de Janeiro",
  rn: "Rio Grande do Norte",
  rs: "Rio Grande do Sul",
  ro: "Rondônia",
  rr: "Roraima",
  sc: "Santa Catarina",
  sp: "São Paulo",
  se: "Sergipe",
  to: "Tocantins",
}).map(([k, v]) => ({ id: k.toUpperCase(), label: v }));

export const TipoOptions = [
  { id: "LAI-CTD-PAGINA", label: "Textos" },
  { id: "LAI-CTD-ARQUIVO", label: "Documentos" },
];

const makeYearOptions = () => {
  const date = new Date();
  const year = date.getFullYear();
  const yearOptions = [];
  for (let y = year; y > year - 5; y--) {
    yearOptions.push({ id: y, label: y });
  }
  return yearOptions;
};

export const YearOptions = makeYearOptions();
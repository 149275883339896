import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivo from "../arquivos/index";

class Contas extends React.Component {
    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <Arquivo hasDateFilter>
                    
                </Arquivo>
            </div>
        );
    }
}

export default Contas;
import Institucional from '../layout/institucional';
import Mapa from '../layout/mapa/index';
import Normativo from "../layout/normativos/index";
import Orcamentos from "../layout/orçamentos/index";
import Licitacao from "../layout/licitacoes/index";
import Credenciamento from '../layout/credenciamento';
import GestaoFinanceira from '../layout/gestao/index';
import Contratos from "../layout/contratos/index";
import Convenios from "../layout/convenios/index";
import Contabel from "../layout/contabel/index";
import Dirigentes from "../layout/dirigentes/index";
import Contas from "../layout/contas/index";
import Projeto from "../layout/projeto/index";
import Infraestrutura from "../layout/infraestrutura/index";
import Perguntas from "../layout/perguntas/index";
import Lei from "../layout/lei/index";
import Ouvidoria from "../layout/ouvidoria/index";
import Cursos from "../layout/cursos/index";
import Sic from '../layout/sic';
import Inovacao from '../layout/inovacao';
import Integridade from '../layout/integridade';
import BuscaPage from '../layout/busca/busca-page'

var indexRoutes = [
    { path: "/institucional", component: Institucional },
    { path: "/mapa", component: Mapa },
    { path: "/normativos", component: Normativo },
    { path: "/orçamentos", component: Orcamentos },
    { path: "/gestaofinanceira", component: GestaoFinanceira },
    { path: "/licitacao", component: Licitacao },
    { path: "/credenciamento", component: Credenciamento },
    { path: "/contratos", component: Contratos },
    { path: "/convenios", component: Convenios },
    { path: "/contabel", component: Contabel },
    { path: "/dirigentes", component: Dirigentes },
    { path: "/contas", component: Contas },
    { path: "/projeto", component: Projeto },
    { path: "/infraestrutura", component: Infraestrutura },
    { path: "/sic", component: Sic },
    { path: "/perguntas", component: Perguntas },
    { path: "/lei", component: Lei },
    { path: "/ouvidoria", component: Ouvidoria },
    { path: "/cursos", component: Cursos },
    { path: "/inovacao", component: Inovacao },
    { path: "/integridade", component: Integridade },
    { path: "/busca", component: BuscaPage },
    { redirect: true, path: "/", pathTo: "/mapa" }
];

export default indexRoutes;

import React from "react";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";

class Projetos extends React.Component {
  constructor(props) {
    super(props);
    this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
    this.state = {
      ppa: "",
      ano: "",
      date: new Date(),
      botaoSelecionado: "Projetos",
      iframeSelecionado: "Projetos",
    };

    this.gerarAnos = this.gerarAnos.bind(this);
  }

  ativarBotaoIframe = (nome) => {
    this.setState({
      botaoSelecionado: nome,
      iframeSelecionado: nome,
    });
  };

  gerarAnos() {
    let data = new Date();
    let ano = parseInt(data.getFullYear());
    let mes = data.getMonth() + 1;
    mes = mes < 10 ? "0" + mes : "" + mes;
    let anos = [];
    for (let inicio = ano; inicio >= 2015; inicio--) {
      anos.push(inicio);
    }
    return anos.map((year, key) => {
      return (
        <option
          key={key}
          value={year === ano ? year + mes : year + "12"}
        >
          {year}
        </option>
      );
    });
  }

  render() {
    return (
      <div
        className={
          this.props.isContraste ? "contraste-display-grid" : "display-grid"
        }
      >
        <Breadcrumb />
        <Arquivos>
          <div className="loadingPainel">
            <div className="submenu-opcoes">
              <button
                className={
                  this.state.botaoSelecionado === "Projetos"
                    ? "opcoes-iframes-selecionado"
                    : "opcoes-iframes"
                }
                onClick={() => this.ativarBotaoIframe("Projetos")}
              >
                Projetos
              </button>
              <button
                className={
                  this.state.botaoSelecionado === "Programas"
                    ? "opcoes-iframes-selecionado"
                    : "opcoes-iframes"
                }
                onClick={() => this.ativarBotaoIframe("Programas")}
              >
                Programas Nacionais
              </button>
              <button
                className={
                  this.state.botaoSelecionado === "ProgramasLocais"
                    ? "opcoes-iframes-selecionado"
                    : "opcoes-iframes"
                }
                onClick={() => this.ativarBotaoIframe("ProgramasLocais")}
              >
                Programas Locais
              </button>
              <button
                className={
                  this.state.botaoSelecionado === "Indicadores"
                    ? "opcoes-iframes-selecionado"
                    : "opcoes-iframes"
                }
                onClick={() => this.ativarBotaoIframe("Indicadores")}
              >
                Indicadores
              </button>
            </div>
            {this.state.iframeSelecionado === "Projetos" && (
              <>
                <Iframe
                  url={
                    "https://paineis-lai.sebrae.com.br/resources/sites/lai/projetos.html?uf=" + this.props.estado.toUpperCase()
                  }
                  width="100%"
                  height="950px"
                  id="myId"
                  display="initial"
                  frameBorder="0"
                  position="relative"
                />
              </>
            )}
            {this.state.iframeSelecionado === "Programas" && (
              <Iframe
                url={
                  "https://paineis-lai.sebrae.com.br/resources/sites/lai/visao-programas.html?&uf=" +
                  this.props.estado.toUpperCase()
                }
                width="100%"
                height="600px"
                id="myId"
                display="initial"
                frameBorder="0"
                position="relative"
              />
            )}
            {this.state.iframeSelecionado === "ProgramasLocais" && (
              <Iframe
                url={
                  "https://paineis-lai.sebrae.com.br/resources/sites/lai/visao-programas-locais.html?uf=" +
                  this.props.estado.toUpperCase()
                }
                width="100%"
                height="600px"
                id="myId"
                display="initial"
                frameBorder="0"
                position="relative"
              />
            )}
            {this.state.iframeSelecionado === "Indicadores" && (
              <Iframe
                url={
                  "https://paineis-lai.sebrae.com.br/resources/sites/lai/indicadores-desempenho.html?&uf=" +
                  this.props.estado.toUpperCase()
                }
                width="100%"
                height="600px"
                id="myId"
                display="initial"
                frameBorder="0"
                position="relative"
              />
            )}
          </div>
        </Arquivos>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  estado: store.principal.estado,
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(Projetos);

import React from "react";
import "./busca.css";
import "./contraste.css";
import "./timeline.css";
import { FaSearch, FaTimes } from "react-icons/fa";
import { connect } from "react-redux";
import { getITILSolution, getITILFollowup } from '../../services/ChamadoService';
import { RingLoader } from 'react-spinners';
import ReactHtmlParser from 'react-html-parser';
import ReCAPTCHA from "react-google-recaptcha";
import { detect } from "detect-browser";
import SweetAlert from 'react-bootstrap-sweetalert';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

class BuscaManifest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isAtivo: false,
            isLoadingITILSolution: false,
            isLoadingITILFollowup: false,
            naoEncontradoITILSolution: false,
            naoEncontradoITILFollowup: false,
            semRespostaItilFollowup: false,
            semRespostaITILSolution: false,
            campoBusca: "",
            resultadoITILSolution: { data: [] },
            resultadoITILFollowup: { data: [] },
            error: null,
            errorinfo: null,
            recaptcha: null,
            captchaValidado: false,
            recaptchaAtivou: false,
            ativar: React.createRef(),
            dadosManifestacao: []
        }
        this.onChange = (editorState) => this.setState({ editorState });
        this.resultadoBusca = this.resultadoBusca.bind(this);
        this.onCorfirmation = this.onCorfirmation.bind(this);

    }

    onCorfirmation = (value) => {
        this.setState({
            recaptcha: value
        });
    }

    resultadoBusca = async (e, id_ticket) => {
        let brow = detect();
        if (brow.name === "edge") {
            e.preventDefault();
            e.stopPropagation();
            window.alert("Para buscas, utilize o navegador Chrome");
            return;
        }
        if (id_ticket !== "") {
            this.setState({ isAtivo: true });
            e.preventDefault();
            e.stopPropagation();
            this.setState({ isLoadingITILSolution: true, isLoadingITILFollowup: true , dadosManifestacao: [] });
            getITILSolution(id_ticket).then((res) => {
                let array = this.state.dadosManifestacao;
                for(let dt of res.data){
                    array.push(dt);
                }
                if(res.data.length !== 0) {
                    this.setState({
                        resultadoITILSolution: res,
                        isLoadingITILSolution: false
                    });
                }else {
                    this.setState({
                        isLoadingITILSolution: false,
                        resultadoITILSolution: { data: [{ date: " ", content: " " }] },
                        semRespostaITILSolution: true
                    }) 
                }
            }).catch((err) => {
                console.error(err);
                this.setState({
                    isLoadingITILSolution: false,
                    resultadoITILSolution: { data: [{ date: " ", content: " " }] },
                    naoEncontradoITILSolution: true
                })
            })

            getITILFollowup(this.state.campoBusca).then((res) => {
                console.log(res);
                let array = this.state.dadosManifestacao;
                for(let dt of res.data){
                    array.push(dt);
                }
                if (res.data.length !== 0) {
                    this.setState({
                        resultadoITILFollowup: res,
                        isLoadingITILFollowup: false,
                        dadosManifestacao: array
                    });
                } else {
                    this.setState({
                        isLoadingITILFollowup: false,
                        resultadoITILFollowup: { data: [{ date: "not found", content: "not found" }] },
                        semRespostaItilFollowup: true
                    })
                }
                console.log(this.state.dadosManifestacao);
            }).catch((err) => {
                console.error(err);
                this.setState({
                    isLoadingITILFollowup: false,
                    resultadoITILFollowup: { data: [{ date: "not found", content: "not found" }] },
                    naoEncontradoITILFollowup: true
                })
            })

        }
        
        e.preventDefault();
        e.persist();
    }

    componentDidCatch(error, errorinfo) {
        this.setState({ error, errorinfo });
        console.error("erro: " + error);
        console.log("informações: " + errorinfo);
    }

    render() {

        const recaptchaRef = React.createRef();

        const timeLine = <div className="busca-table" >
            {<VerticalTimeline layout="1-column">
                {this.state.dadosManifestacao.sort((a,b)=>{
                    if(a.date_creation > b.date_creation){
                        return 1;
                    }
                    return -1;
                }).map((linha, index) => {
                    let cor = linha.date ? 'rgb(49, 135, 247)' : "green";
                    let corBorda = linha.date ? "#2196f3" : "green";
                    return <VerticalTimelineElement key={index}
                        className="vertical-timeline-element"
                        contentStyle={{ boxShadow: "0px 5px 2px rgba(0,0,0,0.05)", backgroundColor: "#f2f9ff", borderTopColor: corBorda }}
                        contentArrowStyle={{ borderRight: '7px solid  red' }}
                        date={linha.date_creation}
                        iconStyle={{ background: cor, color: '#fff' }}
                        position={"right"} >
                        <h3 className="vertical-timeline-element-title"> {linha.date ? "Movimentação interna" : "Resposta"} </h3>
                        <p>
                            {linha.date ? "" : ReactHtmlParser(ReactHtmlParser(linha.content))}
                        </p>
                    </VerticalTimelineElement>
                })}
            </VerticalTimeline>}
        </div>

        const cor_pesquisando = this.props.isContraste ? "yellow" : 'rgb(37, 127, 245)';

        return (
            <div className={this.props.isContraste && "contraste"}>
                <div className={this.props.isContraste ? "contraste-busca-form" : "busca-form"}>
                    <h2>Acompanhe sua solicitação</h2>

                    <div className="busca-form-body">
                        <div className="busca-form-labeld">
                            <label>Número:</label>
                            <input type="number" value={this.state.campoBusca} onChange={(e) => this.setState({ campoBusca: e.target.value })} />
                        </div>
                        <button type="submit" className="busca-form-btn" onClick={(e) => {
                            recaptchaRef.current.execute();
                            if (recaptchaRef.current.getValue()) {
                                this.resultadoBusca(e, this.state.campoBusca);
                            }

                        }} ><FaSearch /></button>
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6Ld1LbUUAAAAAPgSaJirD0tf6olDMnE5bf_dsaNZ"
                            onChange={(e) => {
                                this.onCorfirmation(e);
                                if (this.state.recaptcha !== "") {
                                    if (this.state.campoBusca !== "" && !this.state.recaptchaAtivou) {
                                        this.setState({ isAtivo: true, recaptchaAtivou: true, isLoadingITILSolution: true, isLoadingITILFollowup: true , dadosManifestacao:[] });
                                        getITILSolution(this.state.campoBusca).then((res) => {
                                            let array = this.state.dadosManifestacao;
                                            if(res.data.length !== 0){
                                                for(let dt of res.data) {
                                                    array.push(dt);
                                                }
                                            }
                                            if (res.data.length !== 0) {
                                                this.setState({
                                                    resultadoITILSolution: res,
                                                    isLoadingITILSolution: false,
                                                    dadosManifestacao: array
                                                });
                                            } else {
                                                this.setState({
                                                    isLoadingITILSolution: false,
                                                    resultadoITILSolution: { data: [{ date: "not found", content: "not found" }] },
                                                    semRespostaITILSolution: true
                                                })
                                            }
                                            
                                        }).catch((err) => {
                                            console.error(err);
                                            this.setState({
                                                isLoadingITILSolution: false,
                                                resultadoITILSolution: { data: [{ date: "not found", content: "not found" }] },
                                                naoEncontradoITILSolution: true
                                            })
                                        })

                                        getITILFollowup(this.state.campoBusca).then((res) => {
                                            console.log(res);
                                            let array = this.state.dadosManifestacao;
                                            for(let dt of res.data){
                                                array.push(dt);
                                            }
                                            if (res.data.length !== 0) {
                                                this.setState({
                                                    resultadoITILFollowup: res,
                                                    isLoadingITILFollowup: false,
                                                    dadosManifestacao: array
                                                });
                                            } else {
                                                this.setState({
                                                    isLoadingITILFollowup: false,
                                                    resultadoITILFollowup: { data: [{ date: "not found", content: "not found" }] },
                                                    semRespostaItilFollowup: true
                                                })
                                            }
                                            
                                        }).catch((err) => {
                                            console.error(err);
                                            this.setState({
                                                isLoadingITILFollowup: false,
                                                resultadoITILFollowup: { data: [{ date: "not found", content: "not found" }] },
                                                naoEncontradoITILFollowup: true
                                            })
                                        })

                                    }
                                }
                            }}
                            size="invisible"
                        />
                    </div>

                </div>
                {this.state.isAtivo && <div className="busca-modal-result" onClick={() => this.setState({ isAtivo: false })}>
                    <div className="busca-modal-corpo" >
                        <div>
                            <button onClick={() => this.setState({ isAtivo: false })}> <FaTimes /></button>
                            <h1>Ocorrências</h1>
                        </div>
                        {(this.state.isLoadingITILSolution || this.state.isLoadingITILFollowup || this.state.recaptcha === "") ? <RingLoader
                            sizeUnit={"px"}
                            size={75}
                            color={cor_pesquisando}
                            loading={(this.state.isLoadingITILSolution && this.state.isLoadingITILFollowup)}
                        /> : <div className="busca-lista">
                                {/* <table className="busca-table">
                                    <colgroup>
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                        <col width="5%" /><col width="5%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: "150px", textAlign: "center" }} colSpan="4" > Data </th>
                                            <th colSpan="16"> Descrição </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {linhas.length === 0 ? <tr><td>Sem movimentação</td><td>Sem movimentação</td></tr> : linhas}
                                    </tbody>
                                </table> */}
                                {timeLine}
                            </div>}
                    </div>
                </div>}
                <SweetAlert confirmBtnStyle={{ backgroundColor: "rgb(49, 135, 247)", border: "none", borderRadius: "50px", width: "100px", padding: "10px", color: "white" }}
                    ref="btn" danger title="Nenhum Registro" show={(this.state.naoEncontradoITILSolution && this.state.naoEncontradoITILFollowup)}
                    onConfirm={() => this.setState({ naoEncontradoITILSolution: false, naoEncontradoITILFollowup: false })}>
                    Não há ocorrência cadastrada com esse número {this.state.campoBusca}
                </SweetAlert>
                <SweetAlert confirmBtnStyle={{ backgroundColor: "rgb(49, 135, 247)", border: "none", borderRadius: "50px", width: "100px", padding: "10px", color: "white" }}
                    ref="btn" info title="Solicitação encontrada" show={(this.state.semRespostaITILSolution && this.state.semRespostaItilFollowup)}
                    onConfirm={() => this.setState({ semRespostaITILSolution: false, semRespostaItilFollowup: false })}>
                    A solicitação número {this.state.campoBusca} está pendente de solução
                </SweetAlert>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste
});

export default connect(mapStateToProps)(BuscaManifest);
import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";
import Iframe from "react-iframe";
import { connect } from 'react-redux';

class Infra extends React.Component {
    render() {
        return (
            <div className="display-grid">
                <Breadcrumb />
                <Arquivos>
                    <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/enderecos.html?&uf=" + this.props.estado.toUpperCase()}
                        width="100%"
                        height="1000px"
                        id="myId"
                        display="initial"
                        frameBorder="0"
                        position="relative"
                    />
                </Arquivos>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
    isContraste: store.principal.isContraste
});

export default connect(mapStateToProps)(Infra);
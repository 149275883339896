import axios from "axios";

const API_URL =
  process.env.REACT_APP_LAI_SEARCH_API ??
  "https://buscaprd.sebrae.com.br/api/sn/SebraeNA/search";
const mounturlInit = () => `${API_URL}?fq[]=site:LAI&_setlocale=pt_BR`;

const mountQuery = (value, type) => {
  if (!value) {
    return "";
  }

  return `&fq[]=${type}:${value}`;
};

const mountDateQuery = (year) => {
  if(!year) {
    return '';
  }
  return `&fq[]=publicationDate:[${year}-01-01T00:00:00Z%20TO%20${year}-12-31T00:00:00Z]`
}

export default class BuscaApiService {
  static getByQuery = async ({
    query,
    page,
    sort,
    uf,
    category,
    type,
    rows,
    year,
  }) => {
    const options = {
      method: "GET",
      url:
        mounturlInit() +
        `&q=${query}&p=${page ?? 1}${!!sort ? `&sort=${sort}` : ""}${rows ? `&rows=${rows}` : ''}${mountQuery(
          uf,
          "uf"
        )}${mountQuery(category, "categories")}${mountQuery(type, "type")}${mountDateQuery(year)}`,
    };
    const headers = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
      },
    };
    return await axios(options, headers);
  };
}

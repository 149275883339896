import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";
import Iframe from "react-iframe";
import {connect} from "react-redux";

class Inovacao extends React.Component {

    constructor(props) {
        super(props);
        this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
        this.state = {
            botaoSelecionado: "BolsasInovacao",
            iframeSelecionado: "BolsasInovacao"
        }
    }

    ativarBotaoIframe = (nome) => {
        this.setState({
            botaoSelecionado: nome,
            iframeSelecionado: nome
        })
    }

    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <>

                <Arquivos>
                <div className="submenu-opcoes">
                        <button
                            className={this.state.botaoSelecionado === "BolsasInovacao" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("BolsasInovacao")} >Bolsas de Estímulo à Inovação</button>
                        {/* <button
                            className={this.state.botaoSelecionado === "SubvencaoEconomica" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("SubvencaoEconomica")} >Subvenção Econômica</button>
                        <button
                            className={this.state.botaoSelecionado === "BonusTecnologico" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("BonusTecnologico")} >Bônus Tecnológico</button>
                        <button
                            className={this.state.botaoSelecionado === "Pesquisa" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Pesquisa")} >Pesquisa, Desenvolvimento Tecnológico e Inovação</button>
                        <button
                            className={this.state.botaoSelecionado === "EncomendaTecnologica" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("EncomendaTecnologica")} >Encomenda Tecnológica</button>
                        <button
                            className={this.state.botaoSelecionado === "ParticipacaoSocietaria" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("ParticipacaoSocietaria")} >Participação Societária</button>
                        <button
                            className={this.state.botaoSelecionado === "FundosInvestimentos" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("FundosInvestimentos")} >Fundos de Investimentos</button> */}
                </div>
                {this.state.iframeSelecionado === "BolsasInovacao" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />}
                {/* {this.state.iframeSelecionado === "SubvencaoEconomica" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />}
                {this.state.iframeSelecionado === "BonusTecnologico" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />}
                {this.state.iframeSelecionado === "Pesquisa" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />}
                {this.state.iframeSelecionado === "EncomendaTecnologica" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />}
                {this.state.iframeSelecionado === "ParticipacaoSocietaria" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />}
                {this.state.iframeSelecionado === "FundosInvestimentos" &&  <Iframe
                    url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/bolsas-estimulo-inovacao.html?uf=" + this.props.estado.toUpperCase()}
                    width="100%"
                    height="900px"
                    id="myId"
                    display="initial"
                    frameBorder="0"
                    position="relative"
                    />} */}
                </Arquivos>
                </>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
});


export default connect(mapStateToProps)(Inovacao);
import React from "react";
import Breadcrumb from "../breadcrumb/index";

class Ouvidoria extends React.Component {
    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <p className="display-titulo">
                    Título do Conteúdo
                </p>
            </div>
        );
    }
}

export default Ouvidoria;
/* eslint-disable */
import React from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./sidebar.css";
import "./contraste.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setSideBar } from "../../redux/actions";
import { bindActionCreators } from "redux";

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.ativar = this.ativar.bind(this);
    this.fechar = this.fechar.bind(this);
    this.state = {
      modalAtivo: false,
      contraste: true,
    };
    this.mudarCorSidebar = this.mudarCorSidebar.bind(this);
  }

  ativar() {
    this.setState({ modalAtivo: true });
  }

  fechar() {
    this.setState({ modalAtivo: false });
  }

  mudarCorSidebar = async (item) => {
    let ativo = document.getElementsByClassName("side-bar-active");
    for (let at of ativo) {
      at.setAttribute("class", "");
    }
    await item.setAttribute("class", "side-bar-active");
  };

  componentDidMount() {
    if (this.props.sideBarSelecionado !== "") {
      this.mudarCorSidebar(this.refs[this.props.sideBarSelecionado]);
    }
  }

  render() {
    const sideBarItensEstrutura = [
      {
        name: "Institucional",
        reference: "Institucional",
        url: "/institucional",
      },
      {
        name: "Atos Normativos",
        reference: "Atos_normativos",
        url: "/normativos",
      },
      {
        name: "Estratégia e Resultados",
        reference: "Programas_Projetos_Resultados",
        url: "/projeto",
      },
      {
        name: "Orçamento e Execução Orçamentária",
        reference: "Orçamento_Execucao_Orcamentaria",
        url: "/orçamentos",
      },
      // { name: "Gestão Financeira", reference: "gestao_financeira", url: "/gestaofinanceira" },
      { name: "Licitações", reference: "Licitacoes", url: "/licitacao" },
      {
        name: "Credenciamento",
        reference: "Credenciamento",
        url: "/credenciamento",
      },
      {
        name: "Contratos",
        reference: "Contratos_Convenios",
        url: "/contratos",
      },
      {
        name: "Convênios e Transferências",
        reference: "convenios_transferencia",
        url: "/convenios",
      },
      {
        name: "Demonstrações Contábeis",
        reference: "Demonstracoes_Contabeis",
        url: "/contabel",
      },
      {
        name: "Dirigentes e Empregados",
        reference: "Dirigentes_Empregados",
        url: "/dirigentes",
      },
      {
        name: "Prestação de Contas",
        reference: "Prestacao_Contas",
        url: "/contas",
      },
      {
        name: "Infraestrutura de Atendimento",
        reference: "Infraestrutura",
        url: "/infraestrutura",
      },
      {
        name: "Inovação",
        reference: "Inovacao",
        url: "/Inovacao",
      },
      {
        name: "Integridade",
        reference: "Integridade",
        url: "/integridade",
      },
      // { name: "Perguntas e Respostas", reference: "Perguntas_Respostas", url: "/perguntas", /*redirect:"http://www.sebrae.com.br/sites/PortalSebrae/faq"*/ },
      {
        name: "Perguntas e Respostas",
        reference: "Perguntas_Respostas",
        url: "/perguntas",
        redirect: "http://www.sebrae.com.br/sites/PortalSebrae/faq",
      },
      { name: "Lei de Acesso a Informação", reference: "Lei", url: "/lei" },
      {
        name: "Lei Geral de Proteçao de Dados",
        reference: "LGPD",
        url: "/lgpd",
        redirect:
          "https://sebrae.com.br/sites/PortalSebrae/canais_adicionais/conheca_lgpd",
      },
      {
        name: "Ouvidoria",
        reference: "Ouvidoria",
        url: "/ouvidoria",
        redirect:
          "https://www.sebrae.com.br//sites/PortalSebrae/ouvidoriasebrae",
      },
      {
        name: "Serviço de Informação ao Cidadão (SIC)",
        reference: "esic",
        url: "/sic",
      },
      {
        name: "Cursos Gratuitos",
        reference: "Cursos_Gratuitos",
        url: "/cursos",
        redirect: "http://www.sebrae.com.br/sites/PortalSebrae/cursosonline",
      },
    ];

    const relacao = {
      Institucional: "Institucional",
      Atos_normativos: "Atos Normativos",
      Programas_Projetos_Resultados: "Estratégia e Resultados",
      Orçamento_Execucao_Orcamentaria: "Orçamento e Execução Orçamentária",
      financeira: "Financeira",
      Gestao_Financeira: "Gestao Financeira",
      Licitacoes: "Licitações",
      Credenciamento: "Credenciamento",
      Contratos_Convenios: "Contratos",
      convenios_transferencia: "Convênios e Transferências",
      Demonstracoes_Contabeis: "Demonstrações Contábeis",
      Dirigentes_Empregados: "Dirigentes e Empregados",
      Prestacao_Contas: "Prestação de Contas",
      Infraestrutura: "Infraestrutura de Atendimento",
      Perguntas_Respostas: "Perguntas e Respostas",
      Lei: "Lei de Acesso a Informação",
      Ouvidoria: "Ouvidoria",
      esic: "Serviço de Acesso ao Cidadão (e-SIC)",
      Cursos_Gratuitos: "Cursos Gratuitos",
      Integridade: 'Integridade',
      Inovacao: 'Inovação',
    };

    const sideBarItens = sideBarItensEstrutura.map((item, key) => {
      if (item.redirect) {
        return (
          <li key={key} alt={item.name} ref={item.reference}>
            {" "}
            <a href={item.redirect}>{item.name}</a>
          </li>
        );
      } else {
        return (
          <li
            key={key}
            alt={item.name}
            ref={item.reference}
            onClick={async () => {
              await this.props.setSideBar(item.reference);
              this.props.history.push(
                this.props.uf
                ? `${item.url}?uf=${this.props.uf}`
                : item.url
              );
            }}
          >
            <a href="javascript:void(0);">{item.name}</a>
          </li>
        );
      }
    });
    return (
      <div id="inicioSideBar">
        <div
          className={
            this.props.isContraste ? "contraste-grid-body" : "grid-body"
          }
        >
          <div className="grid-body-sidebar">
            <ul>{sideBarItens}</ul>
          </div>
        </div>
        <div
          className={
            this.props.isContraste
              ? "contraste-grid-body-mobile"
              : "grid-body-mobile"
          }
        >
          <div className="grid-body-option-mobile">
            <div className="grid-body-option-mobile-box">
              <FaBars
                className="grid-body-option-mobile-icon"
                onClick={this.ativar}
              />
              <a>{relacao[this.props.sideBarSelecionado]}</a>
            </div>
            {this.state.modalAtivo && (
              <div className="grid-modal-mobile">
                <div className="grid-body-sidebar-mobile">
                  <FaTimes className="modal-icon" onClick={this.fechar} />
                  <ul>{sideBarItens}</ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
  sideBarSelecionado: store.principal.sideBarSelecionado,
  estado: store.principal.estado,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSideBar }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Sidebar));

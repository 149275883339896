import React from "react";
import "./painel.css";
import Sidebar from "../../components/sidebar/index.js";
import Botoes from "../../components/botoes/index";
import SicConteudo from "../../components/sic";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {setSideBar } from '../../redux/actions';

class Sic extends React.Component {

    render() {
        this.props.setSideBar("esic");
        return (
            <div className="grid">
                { <div className="grid-inline">
                    {!this.props.isContraste && <p className="grid-cabecalho-info">Selecione a finalidade da sua pesquisa</p>}
                    <Botoes />
                </div> }
                <div className="painel-body">
                <Sidebar selecionado="esic"/>
                <SicConteudo/>
                </div>
            </div>
        );
    }
}


const mapStateToProps = store => ({
    isContraste: store.principal.isContraste,
    sideBarSelecionado: store.principal.sideBarSelecionado
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setSideBar}, dispatch);

export default  connect(mapStateToProps,mapDispatchToProps)(Sic);
import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";
import {connect} from "react-redux";

class Normativos extends React.Component {

    render() {
        return (
            <div className={this.props.isContraste ? "contraste-display-grid" : "display-grid"}>
                <Breadcrumb />
                <Arquivos/>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste
});


export default connect(mapStateToProps)(Normativos);
import React from "react";
import "./jumbotron.css";
import "./contraste.css";
import Imagem from "../../images/Group.png";
import { VectorMap } from "react-jvectormap";
import Botoes from "../botoes/index";
import { withRouter } from "react-router-dom";  
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { setEstado, setBotao, setSideBar } from '../../redux/actions';


class Jumbotron extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ufs: {
                'ac': "Acre",
                'al': "Alagoas",
                'ap': "Amapá",
                'am': "Amazonas",
                'ba': "Bahia",
                'ce': "Ceará",
                'df': "Distrito Federal",
                'es': "Espirito Santo",
                'go': "Goias",
                'ma': "Maranhão",
                'mt': "Mato Grosso",
                'ms': "Mato Grosso do Sul",
                'mg': "Minas Gerais",
                'pa': "Pará",
                'pb': "Paraíba",
                'pr': "Paraná",
                'pe': "Pernambuco",
                'pi': "Piauí",
                'rj': "Rio de Janeiro",
                'rn': "Rio Grande do Norte",
                'rs': "Rio Grande do Sul",
                'ro': "Rondônia",
                'rr': "Roraima",
                'sc': "Santa Catarina",
                'sp': "São Paulo",
                'se': "Sergipe",
                'to': "Tocantins"
            },
            uf_default: ""
        }


    }


    definirEstado = async (uf) => {
        this.props.setEstado(uf);
        this.props.setBotao("SebraeEstados");
        // await this.props.setSideBar("esic");
        this.props.history.push("/institucional?unidade=" + uf);
    }

    render() {
        return (
            <div>
                {this.props.isContraste ?
                    <div className="contraste-titulo-grid">
                        <p className="contraste-titulo-solo">Para iniciar, você precisa selecionar a finalidade de sua pesquisa</p>
                    </div>
                    :
                    <div className="main">
                        <div className="box">
                            <div className="texto">
                                <h2>Sebrae, a força do empreendedor brasileiro</h2>
                                <p className="nota-rodapes">Para iniciar, você precisa selecionar a finalidade de sua pesquisa</p>
                            </div>
                            <img src={Imagem} className="imagem" alt="Desenho de fundo branco com uma pessoa exibindo um gráfico" />
                        </div>
                    </div>}
                <div className="web-butoes" >
                    <Botoes uf_atual={this.state.uf_default} />
                    <div className="barra-map" >
                        <VectorMap
                            map={"brazil"}
                            markersSelectable={true}
                            updateSize={true}
                            backgroundColor="transparent" //change it to ocean blue: #0077be
                            zoomOnScroll={false}
                            containerStyle={{
                                width: "100%",
                                height: "600px",
                            }}
                            onRegionClick={(e, code) => {
                                const tips = document.getElementsByClassName("jvectormap-tip");
                                for (let tip of tips) {
                                    tip.style.display = "none";
                                }
                                this.definirEstado(code);
                            }
                            }
                            containerClassName="map"
                            regionStyle={{
                                initial: {
                                    fill: "#e4e4e4",
                                    stroke: "none",
                                    "stroke-width": 0,
                                    "stroke-opacity": 0
                                },
                                hover: {
                                    cursor: "pointer",
                                    fill: "#edb455"
                                },
                                selected: {
                                    fill: "#edb455" //color for the clicked country
                                }
                            }}
                            regionsSelectable={true}
                            series={{
                                regions: [
                                    {
                                        values: {
                                            'ac': 1,
                                            'al': 2,
                                            'ap': 3,
                                            'am': 4,
                                            'ba': 5,
                                            'ce': 6,
                                            'df': 7,
                                            'es': 8,
                                            'go': 9,
                                            'ma': 10,
                                            'mt': 11,
                                            'ms': 12,
                                            'mg': 13,
                                            'pa': 14,
                                            'pb': 15,
                                            'pr': 16,
                                            'pe': 17,
                                            'pi': 18,
                                            'rj': 19,
                                            'rn': 20,
                                            'rs': 21,
                                            'ro': 22,
                                            'rr': 23,
                                            'sc': 24,
                                            'sp': 25,
                                            'se': 26,
                                            'to': 27
                                        },
                                        scale: ['#C8EEFF', '#0071A4'],
                                        normalizeFunction: "polynomial"
                                    }
                                ]
                            }}
                            onRegionTipShow={(e, tip, code) => {
                                //  tip.setAtribute("style","display:none");
                            }}


                        />
                    </div>
                </div>
                {!this.props.isContraste &&
                    <div className="main-mob">
                        <div className="box">
                            <div className="texto">
                                <h2>Sebrae, a força do empreendedor brasileiro</h2>
                                <p className="nota-rodapes-mob">Para iniciar, você precisa selecionar a finalidade de sua pesquisa</p>
                            </div>
                        </div>
                    </div>}
                <div className="barra-mob">
                    <Botoes uf_atual={this.state.uf_default} />
                    <div className="barra-map" >
                        <VectorMap
                            map={"brazil"}
                            backgroundColor="transparent" //change it to ocean blue: #0077be
                            zoomOnScroll={false}
                            containerStyle={{
                                width: "100%",
                                height: "600px",
                            }}
                            onRegionClick={(e, code) => {
                                const tips = document.getElementsByClassName("jvectormap-tip");
                                for (let tip of tips) {
                                    tip.style.display = "none";
                                }
                                this.definirEstado(code);
                            }
                            } //gets the country code
                            containerClassName="map"
                            regionStyle={{
                                initial: {
                                    fill: "#e4e4e4",
                                    stroke: "none",
                                    "stroke-width": 0,
                                    "stroke-opacity": 0
                                },
                                hover: {
                                    cursor: "pointer",
                                    fill: "#edb455"
                                },
                                selected: {
                                    fill: "#edb455" //color for the clicked country
                                }
                            }}
                            regionsSelectable={true}
                            series={{
                                regions: [
                                    {
                                        values: {
                                            'ac': 1,
                                            'al': 2,
                                            'ap': 3,
                                            'am': 4,
                                            'ba': 5,
                                            'ce': 6,
                                            'df': 7,
                                            'es': 8,
                                            'go': 9,
                                            'ma': 10,
                                            'mt': 11,
                                            'ms': 12,
                                            'mg': 13,
                                            'pa': 14,
                                            'pb': 15,
                                            'pr': 16,
                                            'pe': 17,
                                            'pi': 18,
                                            'rj': 19,
                                            'rn': 20,
                                            'rs': 21,
                                            'ro': 22,
                                            'rr': 23,
                                            'sc': 24,
                                            'sp': 25,
                                            'se': 26,
                                            'to': 27
                                        },
                                        scale: ['#C8EEFF', '#0071A4'],
                                        normalizeFunction: "polynomial"
                                    }
                                ]
                            }}
                        />
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
    isContraste: store.principal.isContraste,
    botaoSelecionado: store.principal.botaoSelecionado,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ setEstado, setBotao, setSideBar }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Jumbotron));
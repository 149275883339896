import * as React from 'react';
import { withRouter } from 'react-router-dom';
import "./busca-breadcrumb.css";
import {FaAngleRight} from 'react-icons/fa';

class BuscaBreadCrumb extends React.Component {


    render() {
        return (
            <div className='busca-bread-crumb'>
                <a href='/mapa'>Home</a>
                <FaAngleRight color="#8B99A7" />
                <p>Busca</p>
            </div>
        )
    }
}

export default withRouter(BuscaBreadCrumb);
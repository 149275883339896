import React from 'react';
import Cabecalho from "../../components/cabecalho/index.js";
import Rodape from "../../components/rodape/index.js";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from '../../routes/default'


function Default() {
  return (
    <div >
      <Cabecalho contraste/>
      <Switch>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          return (
            <Route path={prop.path} component={prop.component} key={key} />
          );
        })}     
      </Switch>
      <Rodape/>
    </div>
  );
}

export default Default;

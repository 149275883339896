import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";

class Lei extends React.Component {
    render(){
        return(
            <div className="display-grid">
                <Breadcrumb/>
                <Arquivos>
                    
                </Arquivos>
            </div>
        );
    }
}

export default Lei;
/* eslint-disable */
import React from "react";
import "./cartao.css";
import "./contraste.css";
import { FiDownload } from "react-icons/fi";
import { connect } from 'react-redux';

class Cartao extends React.Component {

    render() {
        return (
            <div className={this.props.isContraste ? "contraste-cartao" :"cartao"}>
                <div className="cartao-body">
                    <div className="cartao-coluna">
                        <p className="cartao-body-titulo">{this.props.titulo ? this.props.titulo : "Nome do arquivo"}</p>
                        <div className="cartao-body-info">
                            <a>Formato : {this.props.formato ? this.props.formato : "Formato do Arquivo"}</a>
                            <a>Tamanho : {this.props.tamanho ? this.props.tamanho : "Tamanho do Arquivo"}</a>
                        </div>
                    </div>
                    <a className="cartao-body-btn"  href={this.props.link} target="_blank" >BAIXAR ARQUIVO <FiDownload className="cartao-body-icon" /> </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste
});


export default  connect(mapStateToProps)(Cartao);  
import * as React from "react";
import "./busca-card.css";
import { FaArrowRight, FaDownload, FaRegFile } from "react-icons/fa";
import ReactHtmlParser from "react-html-parser";
import { CategoriaOptions } from "./busca-options-select";
import { connect } from "react-redux";

 class BuscaCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: props?.content,
      type: props?.type,
    };
    this.openNewTab = this.openNewTab.bind(this);
    this.getFileExtension = this.getFileExtension.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.matchCategory = this.matchCategory.bind(this);
    this.formatSize = this.formatSize.bind(this);
  }

  formatSize() {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    let initIndex = 0;
    let rawSize = this.state.content?.fields?.size ?? 0;
    const scale = 1024;
    while(rawSize > scale) {
      initIndex++;
      rawSize /= scale;
    }
    return parseInt(rawSize) + sizes.at(initIndex);
  }

  matchCategory() {
    return (
      CategoriaOptions.find(
        (c) => c.id === this.state.content?.fields?.categories?.at(0)
      )?.label ?? this.state.content?.fields?.categories?.at(0)
    );
  }

  openNewTab() {
    window.open(this.state.content?.source, "_blank").focus();
  }

  getFileExtension(toUpper) {
    const extension = this.state?.content?.source?.split(".")?.at(-1) ?? "";
    return toUpper ? extension?.toUpperCase() : extension;
  }

  downloadFile(e) {
    e.preventDefault();
    e.stopPropagation();
    let link = document.createElement("a");
    link.href = this.state?.content?.source;
    link.setAttribute("download", `lai.${this.getFileExtension()}`);
    link.click();
  }

  render() {
    if (this.state.content?.fields?.type === "LAI-CTD-PAGINA") {
      return (
        <div className={`busca-card-pagina ${this.props.isContraste ? 'contraste-card' : ''}`}>
          <div>
            <p class="busca-card-categoria">{this.matchCategory()}</p>
            <p class="busca-card-title">
              {ReactHtmlParser(this.state.content?.fields?.title)}
            </p>
            <p class="busca-card-format">Formato: Texto</p>
          </div>
          <div className="busca-card-buttons-wrapper">
            <button onClick={this.openNewTab}>
              <span>
                Acessar <FaArrowRight />
              </span>
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className={`busca-card-pagina ${this.props.isContraste ? 'contraste-card' : ''}`}>
        <div>
          <div className="busca-card-arquivo-categoria">
            <p class="busca-card-categoria">
            {this.matchCategory()}
            </p>
            <span>/</span>
            <p className="busca-card-arquivo-description">
              {ReactHtmlParser(this.state.content?.fields?.description)}
            </p>
          </div>
          <p class="busca-card-title">
            {ReactHtmlParser(this.state.content?.fields?.title)}
          </p>
          <p class="busca-card-format">
            Formato: {this.getFileExtension(true)}   |   Tamanho: {this.formatSize()}
          </p>
        </div>
        <div className="busca-card-buttons-wrapper">
          <button onClick={this.openNewTab}>
            <span>
              Visualizar <FaRegFile />
            </span>
          </button>
          <button onClick={this.downloadFile}>
            <span>
              baixar arquivo <FaDownload />
            </span>
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(BuscaCard);

/* eslint-disable */
import React from "react";
import Logo from "../../images/slogo-azul.png";
import "./rodape.css";
import "./contraste.css";
import { FaTwitter, FaFacebookF } from "react-icons/fa";
import { connect } from 'react-redux';

class Rodape extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            contraste: true
        }
    }

    handleRedirect(path){
        window.open(path);
    }


    render() {
        return (
            <div>
                <footer className={this.props.isContraste ? "contraste-footer":"footer"}>
                    {this.props.isContraste ? 
                    <div className="contraste-footer-col1">
                        <p className="contraste-col1-text">Serviço Brasileiro de Apoio às Micro e Pequenas Empresas (Sebrae)</p>
                    </div>
                        :
                        <div className="coluna-1">
                            <img src={Logo} className="logo" alt="Logo do Sebrae" />
                            <div className="social-icons">
                                <a className="icone"><FaTwitter/></a>
                                <a className="icone"><FaFacebookF/></a>
                            </div>
                        </div>}
                    <div className="coluna-2">
                        <div className="coluna-2-1">
                            <div className="paragrafo-col">
                                <div className="box-col">
                                    <a>Endereço <br />Sede Nacional</a>
                                </div>
                                <div className="box-col">
                                    <a> <strong>SGAS 605, Conjunto A Asa Sul, Brasília - DF 70200-904</strong></a>
                                </div>
                            </div>
                            <div className="paragrafo-col padd">
                                <div className="box-col">
                                    <a>Horário de Funcionamento</a>
                                </div>
                                <div className="box-col">
                                    <a><strong>08:30 às 17:30 <br />de segunda-feira à sexta-feira</strong></a>
                                </div>
                            </div>
                        </div>
                        <div className="coluna-2-2">
                            <div className="paragrafo-col">
                                <div className="box-col-right">
                                    <p>Central de Relacionamento</p>
                                </div>
                                <div className="box-col">
                                    <p><strong>0800 570 0800</strong></p>
                                </div>
                            </div>
                            <div className="paragrafo-col">
                                <div className="box-col-right">
                                    <p>E-mail</p>
                                </div>
                                <div className="box-col ">
                                    <p><strong>ouvidoria@sebrae.com.br</strong></p>
                                </div>
                            </div>
                            <div className="paragrafo-col">
                                <div className="box-col-right">
                                    <p>Site</p>
                                </div>
                                <div className="box-col">
                                    <p><strong>sebrae.com.br</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="coluna-2-mobile">
                        <div className="coluna-2-campo-mobile">
                            <a>Endereço <br />Sede Nacional</a>
                            <a> <strong>SGAS 605, Conjunto A Asa Sul, Brasília - DF 70200-904</strong></a>
                        </div>
                        <div className="coluna-2-campo-mobile">
                            <a>Horário de Funcionamento</a>
                            <a><strong>08:30 às 17:30 <br />de segunda-feira à sexta-feira</strong></a>
                        </div>
                        <div className="coluna-2-campo-mobile">
                            <a>Central de Relacionamento</a>
                            <a><strong>0800 570 0800</strong></a>
                        </div>
                        <div className="coluna-2-campo-mobile">
                            <a>E-mail</a>
                            <a><strong>ouvidoria@sebrae.com.br</strong></a>
                        </div>
                        <div className="coluna-2-campo-mobile">
                            <a>Site</a>
                            <a><strong>sebrae.com.br</strong></a>
                        </div>
                    </div>
                    <div className="coluna-3">
                        <a>Conheça o Sebrae</a>
                        <a>O que fazemos</a>
                        <a>Transparência</a>
                        <a>Licitações</a>
                        <a>Agência Sebrae de Notícias</a>
                        <a onClick={() => this.handleRedirect('https://www.sebrae.com.br//sites/PortalSebrae/ouvidoriasebrae')}>Ouvidoria</a>
                    </div>
                </footer>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste
});

export default  connect(mapStateToProps)(Rodape);
import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";
import {connect} from "react-redux";
import "./integridade.css";

class Integridade extends React.Component {

    render() {
        return (
            <div className={this.props.isContraste ? "contraste-display-grid" : "display-grid"}>
                <Breadcrumb />
                <Arquivos>
                <iframe title="Painel - Compliance Nacional" width="100%" height="550" src="https://app.powerbi.com/view?r=eyJrIjoiZGUyMzcyMTAtZmYzYS00NGViLThlMDYtMmZjZjQ3M2MzZmVjIiwidCI6Ijk3Mjk4MjcxLTFiZDctNGFjNS05MzViLTg4YWRkZWY2MzZjYyIsImMiOjR9" frameborder="0" allowFullScreen="true"></iframe>
                </Arquivos>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste
});


export default connect(mapStateToProps)(Integridade);
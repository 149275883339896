import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";
import Iframe from "react-iframe";
import { connect } from 'react-redux';

class Contratos extends React.Component {

    constructor(props) {
        super(props);
        this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
        this.state = {
            botaoSelecionado: "Contratos",
            iframeSelecionado: "Contratos"
        }
    }

    ativarBotaoIframe = (nome) => {
        this.setState({
            botaoSelecionado: nome,
            iframeSelecionado: nome
        })
    }


    render() {
        return (
            <div className="display-grid">
                <Breadcrumb />
                <Arquivos>

                    <div className="submenu-opcoes">
                        <button
                            className={this.state.botaoSelecionado === "Contratos" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Contratos")} >Contratos</button>
                        <button className={this.state.botaoSelecionado === "Federação" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Federação")}>Confederação e Federações</button>
                        <button className={this.state.botaoSelecionado === "Emendas Parlamentares" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Emendas Parlamentares")}>Emendas Parlamentares</button>
                        <button className={this.state.botaoSelecionado === "Patrocinios" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                            onClick={() => this.ativarBotaoIframe("Patrocinios")}>Patrocínios</button>

                    </div>
                    {/* <p className="display-titulo"> Contratos</p> */}
                    <div className="loadingPainel">
                        {this.state.iframeSelecionado === "Contratos" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/contratos.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="900px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative"
                            padding="0"
                            margin="0" />}
                    </div>
                    {/* <p className="display-titulo">Federação e Confederações</p> */}
                    <div className="loadingPainel">
                        {this.state.iframeSelecionado === "Federação" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/contrato-federacao.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="1750px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative"
                            padding="0"
                            margin="0"
                        />}
                    </div>
                    <div className="loadingPainel">
                        {this.state.iframeSelecionado === "Emendas Parlamentares" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/contrato-emenda.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="1750px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative"
                            padding="0"
                            margin="0"
                        />}
                    </div>
                    <div className="loadingPainel">
                        {this.state.iframeSelecionado === "Patrocinios" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/patrocinios.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="1750px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative"
                            padding="0"
                            margin="0"
                        />}
                    </div>
                </Arquivos>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
});

export default connect(mapStateToProps)(Contratos);
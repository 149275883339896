import * as React from "react";
import { connect } from "react-redux";
import "./busca-tags.css";

 class BuscaTags extends React.Component {
  constructor(props) {
    super(props);

    this.displayTags = this.displayTags.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.isVisible = this.isVisible.bind(this);
  }

  isVisible() {
    return Object.entries(this.props?.tags).find(([_, v]) => !!v);
  }

  handleClose(tag) {
    if (this.props.handleClose) {
      this.props.handleClose(tag);
    }
  }

  displayTags() {
    return Object.entries(this.props?.tags)
      .filter(([_, v]) => !!v && v !== 'Selecione')
      .map(([k, v]) => (
        <span className="busca-tag-body" key={k}>
          <span>{v}</span>
          <button onClick={() => this.handleClose(k)}>x</button>
        </span>
      ));
  }

  render() {
    if (!this.isVisible()) {
      return <React.Fragment />;
    }

    return (
      <div className={`busca-tags-container ${this.props.isContraste ? 'contraste-tag' : ''}`}>
        <button
          className="busca-tags-clear"
          onClick={() => this.props?.clearFilter()}
        >
          <span>Limpar filtros</span>
        </button>
        {this.displayTags()}
      </div>
    );
  }
}

const mapStateToProps = (store) => ({
  isContraste: store.principal.isContraste,
});

export default connect(mapStateToProps)(BuscaTags)
import React from 'react';
import './App.css';
import Jumbotron from "../../components/jumbotron/index.js";

class Home extends React.Component {
  
  render() {
    return (
        <Jumbotron />
    );
  }
}
export default Home;
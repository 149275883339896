import React from "react";
import Iframe from "react-iframe";
import { connect } from 'react-redux';
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos/index";

class Orcamentos extends React.Component {

    constructor(props) {
        super(props);
        this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
        this.state = {
            botaoSelecionado: "Receita",
            iframeSelecionado: "Receita"
        }
    }

    ativarBotaoIframe = nome => {
        this.setState({
            botaoSelecionado: nome,
            iframeSelecionado: nome
        })
    }

    render() {

        return (
            <div className="display-grid">
                <Breadcrumb />
                <>
                    <Arquivos>

                        <div className="submenu-opcoes">

                            <button className={this.state.botaoSelecionado === "Receita" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                                onClick={() => this.ativarBotaoIframe("Receita")}>Receita e Despesa</button>
                            {(this.props.estado !== "" && this.props.estado !== " ") && <button className={this.state.botaoSelecionado === "Região" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                                onClick={() => this.ativarBotaoIframe("Região")}>Receita e Despesa Por Região</button>}
                            <button className={this.state.botaoSelecionado === "Finalidade" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                                onClick={() => this.ativarBotaoIframe("Finalidade")}>Aplicação Por Finalidade</button>
                            <button className={this.state.botaoSelecionado === "Modalidade" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                                onClick={() => this.ativarBotaoIframe("Modalidade")} >Despesa Por Modalidade de Licitação </button>
                            <button className={this.state.botaoSelecionado === "Emenda Parlamentar" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                                onClick={() => this.ativarBotaoIframe("Emenda Parlamentar")} >Emenda Parlamentar </button>
                        </div>
                        {this.state.iframeSelecionado === "Região" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/execucao-orcamentaria-por-receita-e-despesa-regiao.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="1200px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative" />}
                        {this.state.iframeSelecionado === "Receita" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/execucao-orcamentaria-por-receita-e-despesa.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="1200px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative" />}
                        {this.state.iframeSelecionado === "Finalidade" && <><Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/aplicacao-por-finalidade.html?uf=" + this.props.estado.toUpperCase()}
                            width="100%"
                            height="500px"
                            id="myId"
                            display="initial"
                            frameBorder="0"
                            position="relative" />
                            <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/execucao-despesa-tipologia-natureza.html?uf=" + this.props.estado.toUpperCase()}
                                width="100%"
                                height="500px"
                                id="myId"
                                display="initial"
                                frameBorder="0"
                                position="relative" />
                        </>}
                        {this.state.iframeSelecionado === "Modalidade" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/despesa-por-modalidade.html?uf=" + this.props.estado.toUpperCase()}
                                width="100%"
                                height="600px"
                                id="myId"
                                display="initial"
                                frameBorder="0"
                                position="relative" />}
                        {this.state.iframeSelecionado === "Emenda Parlamentar" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/emenda-parlamentar-execucao.html?uf=" + this.props.estado.toUpperCase()}
                                width="100%"
                                height="1200px"
                                id="myId"
                                display="initial"
                                frameBorder="0"
                                position="relative" />}
                    </Arquivos> </>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
    isContraste: store.principal.isContraste
});

export default connect(mapStateToProps)(Orcamentos);
export function setLoading(valor) {
  return {
    type: "LOADING",
    isLoading: valor,
  };
}

export function aumentarFonte() {
  return {
    type: "FONTSIZE",
    fontSize: +2,
  };
}

export function diminuirFonte() {
  return {
    type: "FONTSIZE",
    fontSize: -2,
  };
}

export function mudarContraste() {
  return {
    type: "CONTRASTE",
  };
}

export function setEstado(estado) {
  window.history.replaceState({}, "" , window.location.href.replace(/[?#].*$/, "") + "?unidade=" + estado);
  console.log(estado, 'estado')
  return {
    type: "ESTADO",
    estado: estado,
  };
}

export function setUf(uf) {
  window.history.replaceState({}, "" , window.location.href.replace(/[?#].*$/, "") + "?uf=" + uf);
  console.log(uf, 'uf')
  return {
    type: "UF",
    uf: uf,
  };
}


export function setBotao(escolha) {
  if (
    escolha === "SistemaSebrae" ||
    escolha === "SistemaNacional" ||
    escolha === "SebraeEstados"
  ) {
    return {
      type: "BOTAO",
      botaoSelecionado: escolha,
    };
  } else {
    return {
      type: "BOTAO",
      botaoSelecionado: "",
    };
  }
}

export function setSideBar(opcao) {
  return {
    type: "SIDEBAR",
    sideBarSelecionado: opcao,
  };
}

import React from "react";
import "./painel.css";
import Sidebar from "../../components/sidebar/index.js";
import Botoes from "../../components/botoes/index";
import Projetos from "../../components/projeto";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {setSideBar } from '../../redux/actions';

class Painel extends React.Component {

    render() {
        this.props.setSideBar("Programas_Projetos_Resultados");
        return (
            <div className="grid">
                <div className="grid-inline">
                {!this.props.isContraste && <p className="grid-cabecalho-info">Selecione a finalidade da sua pesquisa</p>} 
                    <Botoes />
                </div>
                <div className="painel-body">
                    <Sidebar />
                    <Projetos/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste,
    sideBarSelecionado: store.principal.sideBarSelecionado
  });
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators({ setSideBar}, dispatch);


export default connect(mapStateToProps,mapDispatchToProps)(Painel);
import React from 'react';
import Institucional from "../../components/institucional/index";
import Botoes from "../../components/botoes/index";
import SideBar from "../../components/sidebar/index";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {setSideBar } from '../../redux/actions';
class InstitucionalLayout extends React.Component {

  render() {
    this.props.setSideBar("Institucional");
    return (
      <div>
        <div className="grid">
          <div className="grid-inline">
            {!this.props.isContraste && <p className="grid-cabecalho-info">Selecione a finalidade da sua pesquisa</p>}
            <Botoes />
          </div>
          <div className="painel-body">
            <SideBar selecionado="Prestacao_Contas" />
            <Institucional />
          </div>
        </div>
      </div>

    );
  }

}

const mapStateToProps = store => ({
  isContraste: store.principal.isContraste,
  sideBarSelecionado: store.principal.sideBarSelecionado
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setSideBar}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(InstitucionalLayout);

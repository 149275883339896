import React from 'react';
import Inovacao from "../../components/inovacao/index";
import SideBar from "../../components/sidebar/index";
import Botoes from "../../components/botoes/index";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {setSideBar } from '../../redux/actions';

class InovacaoLayout extends React.Component {

    render() {
    this.props.setSideBar("Inovacao");
    return (
      <div className="grid">
        <div className="grid-inline">
          {!this.props.isContraste && <p className="grid-cabecalho-info">Selecione a finalidade da sua pesquisa</p>} 
          <Botoes />
        </div>
        <div className="painel-body">
          <SideBar selecionado="Inovacao"/>
          <Inovacao />
        </div>
      </div>


    );
  }

}

const mapStateToProps = store => ({
  isContraste: store.principal.isContraste,
  sideBarSelecionado: store.principal.sideBarSelecionado
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setSideBar}, dispatch);

export default connect(mapStateToProps,mapDispatchToProps)(InovacaoLayout);

import axios from 'axios';

const path = process.env.REACT_APP_CMS_URL;

export default class CmsService {

   static getNome = nome => nome.split(".")[0].toUpperCase();

   static getFormato = formato => {
      let novoNome = formato.split(".");
      return novoNome[novoNome.length - 1].toUpperCase();
   }

   static formatarTamanho = numero => {
      let M = "";
      let retorno = 0;
      if (numero >= (1024 * 1024 * 1024)) {
         M = "GB";
         retorno = parseInt(numero / (1024 * 1024 * 1024));
      } else if (numero >= (1024 * 1024)) {
         M = "MB";
         retorno = parseInt(numero / (1024 * 1024));
      } else if (numero >= 1024) {
         M = "KB";
         retorno = parseInt(numero / (1024));
      } else {
         M = "Byte";
         retorno = numero;
      }
      return "" + retorno + M;
   }


   static getFiles = async queryNames => {
      let options = {
         method: 'GET',
         url: path + `/vgn-webapi/rest/query/executesavedquery.json?queryname=${queryNames}`

      };
      let headers = {
         'headers': {
            'Access-Control-Allow-Origin':'*'
         }
      }
      return axios(options,headers);
   }

   static getSingleFileById = async ID => {
      let options = {
         method: 'GET',
         url: path + "/vgn-webapi/rest/file/load.json?id=" + ID
      };
      let headers = {
         'headers': {
            'Access-Control-Allow-Origin':'*'
         }
      }
      return axios(options,headers);
   }
}
import React from "react";
import Iframe from "react-iframe";
import { connect } from 'react-redux';
import Breadcrumb from "../breadcrumb/index";
import Arquivos from "../arquivos";

class Dirigentes extends React.Component {

    constructor(props) {
        super(props);
        this.ativarBotaoIframe = this.ativarBotaoIframe.bind(this);
        this.state = {
            botaoSelecionado: "Deliberativo",
            iframeSelecionado: "Deliberativo"
        }
    }

    ativarBotaoIframe = nome => {
        this.setState({
            botaoSelecionado: nome,
            iframeSelecionado: nome
        })
    }

    render() {
        return (
            <div className="display-grid">
                <Breadcrumb />
                <Arquivos>
                <div className="submenu-opcoes">
                    <button className={this.state.botaoSelecionado === "Deliberativo" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                        onClick={() => this.ativarBotaoIframe("Deliberativo")}>Conselho Deliberativo</button>
                    <button className={this.state.botaoSelecionado === "Fiscal" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                        onClick={() => this.ativarBotaoIframe("Fiscal")}>Conselho Fiscal</button>
                    <button
                        className={this.state.botaoSelecionado === "Dirigentes" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                        onClick={() => this.ativarBotaoIframe("Dirigentes")} >Diretores</button>
                    <button className={this.state.botaoSelecionado === "Corpo técnico" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                        onClick={() => this.ativarBotaoIframe("Corpo técnico")}>Corpo técnico</button>
                    <button className={this.state.botaoSelecionado === "Estrutura" ? "opcoes-iframes-selecionado" : "opcoes-iframes"}
                        onClick={() => this.ativarBotaoIframe("Estrutura")}>Estrutura Remuneratória</button>
                </div>
                <div>
                    {this.state.iframeSelecionado === "Dirigentes" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/relacao-dos-dirigentes.html?uf=" + this.props.estado.toUpperCase()}
                        width="100%"
                        height="900px"
                        id="myIdDirigentes"
                        display="initial"
                        frameBorder="0"
                        position="relative" />}
                    {this.state.iframeSelecionado === "Corpo técnico" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/relacao-de-empregados.html?uf=" + this.props.estado.toUpperCase()}
                        width="100%"
                        height="1000px"
                        id="myIdEmpregados"
                        display="initial"
                        frameBorder="0"
                        position="relative" />}
                    {this.state.iframeSelecionado === "Estrutura" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/estrutura-remuneratoria.html?uf=" + this.props.estado.toUpperCase()}
                        width="100%"
                        height="1200px"
                        id="myIdEmpregados"
                        display="initial"
                        frameBorder="0"
                        position="relative" />}
                    {this.state.iframeSelecionado === "Fiscal" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/conselho-fiscal.html?uf=" + this.props.estado.toUpperCase()}
                        width="100%"
                        height="800px"
                        id="myIdEmpregados"
                        display="initial"
                        frameBorder="0"
                        position="relative" />}
                    {this.state.iframeSelecionado === "Deliberativo" && <Iframe url={"https://paineis-lai.sebrae.com.br/resources/sites/lai/conselho-deliberativo.html?uf=" + this.props.estado.toUpperCase()}
                        width="100%"
                        height="600px"
                        id="myIdEmpregados"
                        display="initial"
                        frameBorder="0"
                        position="relative"
                        backgroundColor="white" />}
                </div>
               </Arquivos>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    estado: store.principal.estado,
});


export default connect(mapStateToProps)(Dirigentes);
import React from "react";
import "./formulario.css";
import "./contraste.css";
import { FaShareSquare, FaWindowClose } from "react-icons/fa"
import { connect } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { NotificationManager,NotificationContainer } from 'react-notifications';
import InputMask from 'react-input-mask';
import { criarTicket } from '../../services/ChamadoService';
import "../../animations.css";
import SweetAlert from 'react-bootstrap-sweetalert';
import { css } from '@emotion/core';
import { RingLoader } from 'react-spinners';
//import 'bootstrap/dist/css/bootstrap.min.css';

class Formulario extends React.Component {

    constructor(props) {
        super(props);
        this.onCorfirmation = this.onCorfirmation.bind(this);
        this.recuperarEndereco = this.recuperarEndereco.bind(this);
        this.validarEmail = this.validarEmail.bind(this);
        this.enviar = this.enviar.bind(this);
        this.validarCampos = this.validarCampos.bind(this);
        this.limparCampos = this.limparCampos.bind(this);
        this.validarCPF = this.validarCPF.bind(this);
        this.formatarCPF = this.formatarCPF.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.state = {
            recaptcha: false,
            cep: "",
            bairro: "",
            cidade: "",
            UF: "",
            nome: "",
            email: "",
            telefone: "",
            problema: "",
            assunto: "",
            success: false,
            isLoading: false,
            error: "",
            errorinfo: "",
            cpf:""
        }
    }

    limparCampos = () => {
        this.setState({
            cep: "",
            bairro: "",
            cidade: "",
            UF: "",
            nome: "",
            email: "",
            telefone: "",
            problema: "",
            assunto: "",
            cpf:""
        })
    }

    onCorfirmation = value => {
        this.setState({
            recaptcha: value
        })
    }

    validarEmail = () => {
        let email = this.state.email;
        if (email.indexOf("@") > 0)
            return true;
        return false;
    }

    validarCampos = () => {
        if (this.state.nome === "" || this.state.cidade === "" || this.state.cep === "" || this.state.assunto === "" ||
            this.state.UF === "" || this.state.problema === "" || this.state.email === "" || this.state.cpf === "") {
            return false;
        }
        if(this.state.cpf) {
            if(this.state.cpf.length===14) {
                let cpfExiste = this.validarCPF();
                if(!cpfExiste)
                    return false
            }
        }
        return true;
    }

    handleValidation(){
        let fieldsToValidate = [
            {label: 'Nome do usuário', value: this.state.nome},
            {label: 'Cidade', value: this.state.cidade},
            {label: 'Assunto da dsolicitação', value: this.state.assunto},
            {label: 'Estado - UF', value: this.state.UF},
            {label: 'Problema da solicitação', value: this.state.problema},
            {label: 'E-mail do usuário', value: this.state.email},
            {label: 'CPF do usuário', value: this.state.cpf},
        ].map(({label,value}) => {
            if(!value) {
                return label;
            };
            return "";
        }).filter(value => !!value);
        if(fieldsToValidate.length === 0) {
            if(this.state.cpf && this.state.cpf.length === 14) {
                if(this.validarCPF()) {
                    return true
                }else {
                    NotificationManager.error('Preencha o CPF com um cpf válido', 'Este CPF é inválido', 5000);
                    return false;
                }
            } else {
                NotificationManager.error('Preencha o CPF com um cpf válido', 'Este CPF é inválido', 5000);
                return false;
            }
        }
        let mesagge = "Campos obrigatórios";
        fieldsToValidate.forEach(item => mesagge += item + '; ');
        NotificationManager.error(mesagge, 5000);
        return false;
    }

    enviar = async e => {
        //this.handleValidation();
        if (this.handleValidation()) {
            this.setState({
                isLoading: true
            })
            e.preventDefault()
            e.stopPropagation();
            let descricao = "nome: " + this.state.nome + "\n" +
                "CPF: " + this.state.cpf + "\n" +
                "CEP: " + this.state.cep + "\n" +
                "Cidade: " + this.state.cidade + "\n" +
                "Bairro: " + this.state.bairro + "\n" +
                "Estado: " + this.state.UF + "\n" +
                "Telefone: " + this.state.telefone + "\n" +
                "Email: " + this.state.email + "\n" +
                "conteudo: \n" + this.state.problema;
            try {
                let ticket = await criarTicket(descricao, this.state.assunto, this.state.email, 42);
                this.setState({ idChamado: ticket, success: true });
            } catch(error){
                NotificationManager.error('Erro ao enviar solicitação, por favor entre em contato com o suporte.', 5000);
                console.log(error);
            }
        }
        e.preventDefault()
        e.stopPropagation();
        this.setState({
            isLoading: false
        })
    }

    recuperarEndereco = cepMask => {
        let cep = cepMask ? cepMask.replace(".", "").replace("-", "").replace("_", "") : "";
        if (cep.length !== 8) {
            this.setState({
                cep: "",
                bairro: "",
                cidade: "",
                UF: ""
            });
            return
        }
        axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(r => {

            if (!r.data.erro) {
                this.setState({
                    cidade: r.data.localidade,
                    UF: r.data.uf,
                    bairro: r.data.bairro
                });
            } else {
                NotificationManager.warning('Preencha o endereço sem informar o CEP', 'CEP não encontrado', 5000);
                this.setState({
                    cep: "",
                    cidade: "",
                    UF: "",
                    bairro: ""
                });
            }
        }).catch(error => {
            console.log(error)
            NotificationManager.error('Preencha o endereço sem informar o CEP', 'Serviço de busca', 5000);
            this.setState({ cep: "" });
        });
    }

    formatarCPF = (v) => {
        v = v.replace(/\D/g, "");                    //Remove tudo o que não é dígito
        v = v.replace(/(\d{3})(\d)/, "$1.$2");       //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d)/, "$1.$2");       //Coloca um ponto entre o terceiro e o quarto dígitos
        v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2"); //Coloca um hífen entre o terceiro e o quarto dígitos
        this.setState({ cpf: v });
    }

    validarCPF = () => {
        let numero = this.state.cpf;
        numero = numero.replace(/\D/g, "");
        let lista = numero.split("");
        let soma = 0;
        let teto = 10;
        for (let i = 0; i < lista.length - 2; i++) {
            soma += parseInt(lista[i]) * teto;
            teto--;
        }
        let validador1 = (11 - (soma % 11)) > 9 ? 0 : 11 - (soma % 11);
        if (validador1 !== parseInt(lista[9])) {
            return false;
        }
        soma = 0;
        let teto2 = 11;
        for (let i = 0; i < lista.length - 1; i++) {
            soma += parseInt(lista[i]) * teto2;
            teto2--;
        }
        let validador2 = (11 - (soma % 11)) > 9 ? 0 : 11 - (soma % 11);
        if (validador2 !== parseInt(lista[10])) {
            return false;
        }
        return true;
    }

    componentDidCatch(error, errorinfo) {
        this.setState({ error, errorinfo });
        console.log(error, errorinfo);
    }

    render() {

        const override = css`
            display: block;
            margin: 0 auto;
            border-color: blue;
        `;

        return (
            <div>
                <form onSubmit={(e) => this.enviar(e)}>
                    <div className={this.props.isContraste ? "contraste-form" : "form"}>
                        <h3 className="formulario-titulo">Registre aqui sua solicitação</h3>
                        <div className="form-div">
                            <label>Nome: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <input type="text" value={this.state.nome} required={true} placeholder="Informe seu nome..." onChange={(e) => this.setState({ nome: e.target.value })} />
                        </div>
                        <div className="form-div">
                            <label>CPF: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <input type="text" maxLength="14" value={this.state.cpf} required={true} placeholder="Informe seu cpf..." onChange={(e) => this.formatarCPF(e.target.value)} />
                        </div>
                        <div className="form-div">
                            <label>E-mail: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <input type="email" value={this.state.email} required={true} placeholder="Informe seu e-mail..." onChange={(e) => this.setState({ email: e.target.value })} />

                        </div>
                        <div className="form-div">
                            <label>CEP:</label>
                            <InputMask value={this.state.cep} placeholder="digite seu CEP..." mask="99.999-999" maskChar={null} onChange={(e) => {
                                this.recuperarEndereco(e.target.value); this.setState({ cep: e.target.value })
                            }} />
                        </div>
                        <div className="form-div">
                            <label>Cidade: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <input value={this.state.cidade} type="text" required={true} placeholder="Informe sua cidade..." onChange={(e) => this.setState({ cidade: e.target.value })} />

                        </div>
                        <div className="form-div">
                            <label>Bairro:</label>
                            <input value={this.state.bairro} type="text" placeholder="Informe o seu bairro..." onChange={(e) => this.setState({ bairro: e.target.value })} />
                        </div>
                        <div className="form-div">
                            <label>Estado: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <input value={this.state.UF} type="text" required={true} placeholder="Informe o estado de sua cidade..." onChange={(e) => this.setState({ UF: e.target.value })} />
                        </div>
                        <div className="form-div">
                            <label>Telefone:</label>
                            <InputMask value={this.state.telefone} placeholder="digite seu telefone..." mask="(99) 99999 - 9999" maskChar={null} onChange={(e) => this.setState({ telefone: e.target.value })} />
                        </div>
                        <div className="form-div">
                            <label>Assunto: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <input value={this.state.assunto} type="text" required={true} placeholder="Informe o assunto relacionado ao problema..." onChange={(e) => this.setState({ assunto: e.target.value })} />
                        </div>
                        <div className="form-div">
                            <label>Descrição da solicitação: *</label>
                            <p className="form-info">Campo Obrigatório</p>
                            <textarea value={this.state.problema} type="text" required={true} placeholder="Descreva o problema..." rows="5" col="5" onChange={(e) => this.setState({ problema: e.target.value })} />
                        </div>
                        <div className="form-recap">
                            <ReCAPTCHA
                                sitekey="6LcDLLUUAAAAAKNb-QV3Ue13wAABn9-ikvhLbVvN"
                                onChange={this.onCorfirmation}
                            />
                        </div>
                        {this.state.recaptcha ? (<div className="form-btn">
                            <button type="submit" >ENVIAR <FaShareSquare className="form-icon" /></button>
                        </div>) : (<div className="form-btn-disable">
                            <button>ENVIAR <FaWindowClose className="form-icon" /></button>
                        </div>)}
                        <SweetAlert confirmBtnStyle={{ backgroundColor: "rgb(49, 135, 247)", borderRadius: "50px;" }} ref="btn" success title="Manisfestação registrada!"
                        show={this.state.success} onConfirm={() => {this.setState({ success: false }); this.limparCampos()}}>
                            Seu protocolo é o: {this.state.idChamado}
                        </SweetAlert>
                    </div>
                </form>
                {this.state.isLoading && <div className="form-loading-modal">
                    <div RingLoader className='sweet-loading'>
                        <RingLoader
                            css={override}
                            sizeUnit={"px"}
                            size={75}
                            color={'rgb(37, 127, 245)'}
                            loading={this.state.isLoading}
                        />
                    </div>
                </div>}
                <NotificationContainer/>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    isContraste: store.principal.isContraste
});

export default connect(mapStateToProps)(Formulario);

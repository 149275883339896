/* eslint-disable */
import React   from 'react';
import "./cabecalho.css";
import "./contraste.css";
import Logo from "../../images/slogo-azul.png";
import Sic from "../../images/sic_branco_letras_resumido.png";
import Sic_C from "../../images/sic_branco_letras_resumido_cont.png";
import {withRouter} from "react-router-dom";
import { aumentarFonte, diminuirFonte, mudarContraste, setSideBar } from '../../redux/actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Cabecalho extends React.Component {

    constructor(props) {
        super(props);
        this.aumentar_fonte = this.aumentar_fonte.bind(this);
        this.diminuir_fonte = this.diminuir_fonte.bind(this);
        this.toggleContraste = this.toggleContraste.bind(this);
        document.documentElement.style.setProperty('--font-size',this.props.fontSize + "px");
    }

     aumentar_fonte = async () =>  {
        await this.props.aumentarFonte();
        document.documentElement.style.setProperty('--font-size',this.props.fontSize + "px");
    }

    diminuir_fonte = async () => {
        await this.props.diminuirFonte();
        document.documentElement.style.setProperty('--font-size',this.props.fontSize + "px");
    }

    toggleContraste() {
        if(!this.props.isContraste){
            document.body.style.backgroundColor = "#333";
        }else{
            document.body.style.backgroundColor = "#F2F9FF";
        }
        this.props.mudarContraste()
    }

    componentWillMount() {
        if(!this.props.isContraste){
            document.body.style.backgroundColor = "#F2F9FF";
        }else{
            document.body.style.backgroundColor = "#333";
        }
    }

    render() {

        return (
            <div>
                <header className={this.props.isContraste ? "contraste-header-nav-mob" : "header-nav-mob"}>
                    <div className="acessibilidade-mob">
                        <div className="divisor">
                            <a alt="Diminuir Fonte" onClick={() => this.diminuir_fonte()}>A-</a>
                            <a alt="Aumentar Fonte" onClick={() => this.aumentar_fonte()}>A+</a>
                        </div>
                        <a className="barr" alt="Alto Contraste" onClick={() => this.toggleContraste()} style={{textDecoration:"none"}}>Alto Contraste</a>
                        <img className="a-dir sic-logo-mobile" src={this.props.isContraste ? Sic_C : Sic} alt="Logo do E-SIC" onClick={ ()=> this.props.history.push("/sic")}/>
                    </div>
                    <div className="nav-mob" >
                        <img className="logo" src={Logo} alt="Logo do Sebrae" onClick={ ()=> this.props.history.push("/mapa")} />
                        <a className="brand" alt="Portal da Transparência e Prestação de Contas " onClick={ ()=> this.props.history.push("/mapa")}>Portal da Transparência e Prestação de Contas </a>
                    </div>
                </header>
                <header className= {this.props.isContraste ? "contraste-header-nav-pc" :  "header-nav-pc"}  ref="header">
                    <img className="logo" src={Logo} alt="Logo do Sebrae" onClick={ ()=> this.props.history.push("/mapa")} />
                    <a className="a-esq brand" alt="Portal da Transparência e Prestação de Contas " onClick={ ()=> this.props.history.push("/mapa")} >Portal da Transparência e Prestação de Contas </a>
                    <img className="a-dir sic-logo" src={this.props.isContraste ? Sic_C : Sic} alt="Logo do E-SIC" onClick={ async ()=> {await this.props.setSideBar("esic")  ;this.props.history.push("/sic")}}/>
                    <a className="a-dir barr" alt="Alto Contraste" onClick={() => this.toggleContraste()}>Alto Contraste</a>
                    <a className="a-dir" alt="Diminuir Fonte" onClick={() => this.diminuir_fonte()}>A-</a>
                    <a className="a-dir" alt="Aumentar Fonte" onClick={() => this.aumentar_fonte()}>A+</a>
                </header>
            </div>
        );
    }
}

const mapStateToProps = store => ({
    fontSize: store.principal.fontSize,
    isContraste: store.principal.isContraste
  });

  const mapDispatchToProps = dispatch =>
    bindActionCreators({ aumentarFonte, diminuirFonte, mudarContraste, setSideBar}, dispatch);


export default  connect(mapStateToProps,mapDispatchToProps)(withRouter(Cabecalho));

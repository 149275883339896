import React from "react";
import Breadcrumb from "../breadcrumb/index";
import Iframe from "react-iframe";

class Perguntas extends React.Component {   

    render() {
        return (
            <div className="display-grid">
                <Breadcrumb />
                <div className="loadingPainel">
                    <Iframe url={"https://www.sebrae.com.br/sites/PortalSebrae/faq"}
                        width="100%"
                        height="1800px"
                        id="myId"
                        display="initial"
                        frameBorder="0"
                        position="relative"
                        sandbox="allow-same-origin allow-scripts"
                        overflow="hidden"
                    />
                </div>
            </div>
        );
    }
}

export default Perguntas;